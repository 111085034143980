<template>
  <VWidget content-class="pa-0">
    <template v-slot:title>{{ $t('labels.clientPolicies') }}</template>
    <template v-slot:header-actions v-if="hasGroupDiscount">
      <v-chip small color="success">{{ $t('labels.groupDiscount') }}</v-chip>
    </template>
    <template v-slot:content>
      <policy-summary v-for="(policy, idx) in policies" :key="`policy-${policy.id}`" :idx="idx" :policy="policy" />
    </template>
  </VWidget>
</template>

<script>
import { VWidget } from '@argon/app/components'
import { InsuranceMixin } from '../mixins'
import PolicySummary from './PolicySummary'

export default {
  name: 'VPoliciesSummary',
  mixins: [InsuranceMixin],
  components: { VWidget, PolicySummary }
}
</script>

<style></style>
