export default [
  {
    path: '/deals',
    component: () => import('@argon/app/components/AppLayout'),
    props: { nav: 'organizations' },
    children: [
      {
        path: '',
        name: 'boards',
        props: true,
        component: () => import('./views/Boards'),
        meta: {
          perimeterAction: 'read'
        },
        children: [
          {
            path: 'new',
            name: 'newBoard',
            props: true,
            component: () => import('@argon/evo/boards/views/ModalNewBoard'),
            meta: {
              perimeterAction: 'read'
            }
          },
          {
            path: 'restore',
            name: 'boardRestore',
            props: true,
            component: () => import('@argon/evo/boards/views/ModalBoardsRestore'),
            meta: {
              perimeterAction: 'read'
            }
          }
        ]
      },
      {
        path: ':boardRef',
        component: () => import('./views/BoardContainer'),
        props: true,
        meta: {
          perimeterAction: 'read'
        },
        children: [
          {
            path: '',
            name: 'board',
            component: () => import('./views/Board'),
            props: true,
            meta: {
              perimeterAction: 'read'
            },
            children: [
              {
                path: 'table',
                name: 'tableView',
                props: true,
                component: () => import('./views/TableView'),
                meta: {
                  perimeterAction: 'read'
                },
                children: [
                  {
                    path: 'properties',
                    name: 'tabledealBoardProperties',
                    props: true,
                    component: () => import('./views/ModalProperties'),
                    meta: {
                      perimeterAction: 'read'
                    }
                  },
                  {
                    path: 'users',
                    name: 'tabledealBoardUsers',
                    props: true,
                    component: () => import('@argon/evo/boards/views/ModalUsers'),
                    meta: {
                      perimeterAction: 'read'
                    }
                  },
                  {
                    path: 'restore',
                    name: 'tableentityRestore',
                    props: true,
                    component: () => import('@argon/evo/entities/views/ModalEntitiesRestore'),
                    meta: {
                      perimeterAction: 'read'
                    }
                  }
                ]
              }
            ]
          },

          {
            path: 'cards/:entityId',
            name: 'boardCard',
            component: () => import('./views/DealPage'),
            props: true,
            meta: {
              perimeterAction: 'read'
            },
            children: [
              {
                path: 'advisor/:advisorId',
                name: 'nestedAdvisor',
                props: true,
                component: () => import('./views/AdvisorModal'),
                meta: {
                  perimeterAction: 'read'
                }
              },
              {
                path: 'broker/:brokerId',
                name: 'nestedBroker',
                props: true,
                component: () => import('./views/BrokerModal'),
                meta: {
                  perimeterAction: 'read'
                }
              },
              {
                path: 'contract-number',
                name: 'nestedContractNumber',
                props: true,
                component: () => import('./views/ContractNumberModal'),
                meta: {
                  perimeterAction: 'read'
                }
              },
              {
                path: 'commissioning',
                name: 'nestedCommissioning',
                props: true,
                component: () => import('./views/CommissioningModal'),
                meta: {
                  perimeterAction: 'read'
                }
              },
              {
                path: 'firm/:firmCode',
                name: 'nestedFirm',
                props: true,
                component: () => import('./views/FirmModal'),
                meta: {
                  perimeterAction: 'read'
                }
              },
              {
                path: 'loan-amounts',
                name: 'nestedLoanAmounts',
                props: true,
                component: () => import('./views/LoanAmountsModal'),
                meta: {
                  perimeterAction: 'read'
                }
              },
              {
                path: 'state',
                name: 'nestedDealState',
                props: true,
                component: () => import('./views/DealStateModal'),
                meta: {
                  perimeterAction: 'read'
                }
              },
              {
                path: 'blocker',
                name: 'nestedDealBlocker',
                props: true,
                component: () => import('./views/DealBlockerModal'),
                meta: {
                  perimeterAction: 'read'
                }
              },
              {
                path: 'client/:clientId',
                name: 'nestedClient',
                props: true,
                component: () => import('./views/NestedModalClient'),
                meta: {
                  perimeterAction: 'read'
                }
              },
              {
                path: 'policy/:policyId/beneficiary/:beneficiaryId',
                name: 'nestedBeneficiary',
                props: true,
                component: () => import('../insurance/beneficiaries/BeneficiaryModal'),
                meta: {
                  perimeterAction: 'read'
                }
              },
              {
                path: 'policy/:policyId/product/:productId',
                name: 'nestedProduct',
                props: true,
                component: () => import('../insurance/products/ProductModal'),
                meta: {
                  perimeterAction: 'read'
                }
              },
              {
                path: 'responses/:clientId',
                name: 'nestedResponses',
                props: true,
                component: () => import('../insurance/responses/ResponsesModal'),
                meta: {
                  perimeterAction: 'read'
                }
              },
              {
                path: 'decision/:clientId',
                name: 'nestedDecision',
                props: true,
                component: () => import('../insurance/decision/DecisionModal'),
                meta: {
                  perimeterAction: 'read'
                }
              },
              {
                path: 'compliance/:fileId',
                name: 'addCompliance',
                props: true,
                component: () => import('./views/ComplianceModal'),
                meta: {
                  perimeterAction: 'read'
                }
              },
              {
                path: 'logbook/:logId',
                name: 'logEntry',
                props: true,
                component: () => import('../logbook/deals/components/ModalLogBookEntry'),
                meta: {
                  perimeterAction: 'read'
                }
              }
            ]
          }
        ]
      }
    ]
  }
]
