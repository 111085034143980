import { axios } from '@argon/app/axios'
import { isObjectLike, isArray } from 'lodash/lang'
import { concat } from 'lodash/array'

const NAMESPACE = 'dealLog'

const joinKeys = (key) => (isArray(key) ? key.join('_') : key)
const matchObject = (label, line) => {
  switch (label) {
    case 'clients':
      return { label, previous_value: 'N/A', value: `${line[0]}:${line[1].client.display}` }
    case 'insurance':
      return { label, previous_value: 'N/A', value: `${line[0]}:${line[1].display}` }
    default:
      break
  }
}

const matchValues = (label, line) =>
  isObjectLike(line[1]) ? matchObject(label, line) : { label, previous_value: 'N/A', value: line[1] }

const formatAdd = (addLine) => {
  let label = joinKeys(addLine[1]) || addLine[2][0][0]
  let rawChanges = addLine[2]
  let filteredChanges = rawChanges.map((x) => matchValues(label, x)).filter((x) => x !== undefined)
  return filteredChanges
}

const formatChange = (changeLine) => ({
  label: joinKeys(changeLine[1]),
  previous_value: changeLine[2][0] || 'N/A',
  value: changeLine[2][1]
})
const formatRemove = (rmLine) => ({
  label: joinKeys(rmLine[1]),
  previous_value: 'removed',
  value: ''
})

const formatSnapshot = (snap) => {
  let additions = snap.data.diff.filter((x) => x[0] === 'add').map(formatAdd)
  let allAdds = concat(...additions)
  let changes = snap.data.diff.filter((x) => x[0] === 'change').map(formatChange)
  let removed = snap.data.diff.filter((x) => x[0] === 'remove').map(formatRemove)
  let diff = concat(allAdds, changes, removed)
  return { ...snap, diff }
}

const state = {
  logs: []
}

const mutations = {
  SET_LOGS: (state, payload) => {
    state.logs = payload
  }
}
const actions = {
  listLogs: ({ commit }, entityId) => {
    return new Promise((resolve, reject) => {
      axios({
        url: `/deals/${entityId}/logbook`,
        method: 'GET'
      })
        .then(({ data }) => {
          commit('SET_LOGS', data.items)
          resolve(data.items)
        })
        .catch(reject)
    })
  },
  clearLogs: ({ commit }) => {
    commit('SET_LOGS', [])
  },
  getLogbookEntry: ({}, payload) => {
    const { entityId, logId } = payload
    return new Promise((resolve, reject) => {
      axios({
        url: `/deals/${entityId}/logbook/${logId}`,
        method: 'GET'
      })
        .then(({ data }) => {
          resolve(data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
}
const getters = {
  logs: (state) => state.logs
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}

export { NAMESPACE }
