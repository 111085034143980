import { render, staticRenderFns } from "./AddressesWidget.vue?vue&type=template&id=785ff86d&scoped=true&"
import script from "./AddressesWidget.vue?vue&type=script&lang=js&"
export * from "./AddressesWidget.vue?vue&type=script&lang=js&"
import style0 from "./AddressesWidget.vue?vue&type=style&index=0&id=785ff86d&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "785ff86d",
  null
  
)

/* custom blocks */
import block0 from "./AddressesWidget.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';
installComponents(component, {VBtn,VCol,VIcon,VRow,VSpacer,VToolbar,VToolbarTitle})
