export default [
  {
    path: '/leads',
    component: () => import('@argon/app/components/AppLayout'),
    props: { nav: 'organizations' },
    children: [
      {
        path: '',
        name: 'leadBoards',
        props: true,
        component: () => import('./views/Boards'),
        meta: {
          perimeterAction: 'read'
        }
      },
      {
        path: ':boardRef',
        component: () => import('./views/BoardContainer'),
        props: true,
        meta: {
          perimeterAction: 'read'
        },
        children: [
          {
            path: '',
            name: 'leadBoard',
            component: () => import('./views/Board'),
            props: true,
            meta: {
              perimeterAction: 'read'
            },
            children: [
              {
                path: 'table',
                name: 'leadTableView',
                props: true,
                component: () => import('./views/TableView'),
                meta: {
                  perimeterAction: 'read'
                },
                children: [
                  {
                    path: 'properties',
                    name: 'tableleadBoardProperties',
                    props: true,
                    component: () => import('./views/ModalProperties'),
                    meta: {
                      perimeterAction: 'read'
                    }
                  },
                  {
                    path: 'users',
                    name: 'tableleadBoardUsers',
                    props: true,
                    component: () => import('@argon/evo/boards/views/ModalUsers'),
                    meta: {
                      perimeterAction: 'read'
                    }
                  }
                ]
              }
            ]
          },
          {
            path: 'cards/:entityId',
            name: 'leadPage',
            component: () => import('./views/LeadPage'),
            props: true,
            meta: {
              perimeterAction: 'read'
            }
          }
        ]
      }
    ]
  },
  {
    path: '/quick/deal',
    component: () => import('@argon/app/components/AppLayout'),
    props: { nav: 'organizations' },
    children: [
      {
        path: '',
        name: 'newLead',
        props: true,
        component: () => import('./views/IndividualWizard'),
        meta: {
          perimeterAction: 'read'
        },
        children: [
          {
            path: 'clients/:clientId',
            name: 'nestedLeadClient',
            props: true,
            component: () => import('./views/ModalLeadClient'),
            meta: {
              perimeterAction: 'read'
            }
          },
          {
            path: 'notes',
            name: 'nestedLeadNote',
            props: true,
            component: () => import('./views/ModalLeadNote'),
            meta: {
              perimeterAction: 'read'
            }
          },
          {
            path: 'files',
            name: 'nestedLeadFile',
            props: true,
            component: () => import('./views/ModalLeadFile'),
            meta: {
              perimeterAction: 'read'
            }
          }
        ]
      }
    ]
  }
]
