<template>
  <component :is="dynamicComponent" :idx="idx" :policy="policy" />
</template>

<script>
import { IAGPolicySummary } from '../iag'
import { CLAPolicySummary } from '../cla'

export default {
  name: 'PolicySummary',
  components: { CLAPolicySummary, IAGPolicySummary },
  computed: {
    dynamicComponent() {
      switch (this.policy.insurer) {
        case 'IAG':
          return 'IAGPolicySummary'
          break

        default:
          return 'CLAPolicySummary'
          break
      }
    }
  },
  props: {
    idx: { type: Number, required: false, default: 0 },
    policy: { type: Object, required: true }
  }
}
</script>

<style></style>
