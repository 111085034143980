<template>
  <v-chip small :color="color">{{ $t(`labels.${status}`) }}</v-chip>
</template>

<script>
import { ACCEPTED, REJECTED } from '../store'
export default {
  name: 'ApplicantStatus',
  props: {
    status: String
  },
  computed: {
    color() {
      switch (this.status) {
        case ACCEPTED:
          return 'success'
          break
        case REJECTED:
          return 'error'
          break

        default:
          return 'warning'
      }
    }
  }
}
</script>

<style></style>
