import organizationPerimeter from '@argon/iam/organizations/perimeters'
import OrganizationGoverness from '@argon/iam/organizations/governesses'
import SimplGoverness from '../governesses'

export default [
  {
    path: '/organizations',
    props: { nav: 'main' },
    component: () => import('@argon/app/components/AppLayout'),
    children: [
      {
        path: '',
        name: 'organizations',
        props: true,
        component: () => import('@argon/iam/organizations/views/Organizations'),
        meta: {
          perimeter: organizationPerimeter,
          perimeterAction: 'read'
        }
      }
    ]
  },
  {
    path: '/settings',
    props: { nav: 'organizations' },
    component: () => import('@argon/app/components/AppLayout'),
    meta: {
      governess: SimplGoverness,
      perimeter: organizationPerimeter,
      perimeterAction: 'manage'
    },
    children: [
      {
        path: '',
        name: 'organizationSettings',
        component: () => import('./views/OrganizationSettings'),
        props: true,
        children: [
          {
            path: 'properties',
            name: 'editOrganization',
            component: () => import('@argon/iam/organizations/views/ModalProperties'),
            props: true,
            meta: {
              perimeter: organizationPerimeter,
              perimeterAction: 'read'
            }
          },
          {
            path: 'preferences',
            name: 'editOrgPreferences',
            component: () => import('@argon/iam/organizations/views/OrganizationPreferencesModal'),
            props: true,
            meta: {
              perimeter: organizationPerimeter,
              perimeterAction: 'read'
            }
          },
          {
            path: 'profile/:userId',
            name: 'orgUserProfile',
            component: () => import('./views/ModalProfile'),
            props: true,
            meta: {
              perimeter: organizationPerimeter,
              perimeterAction: 'read'
            }
          },
          {
            path: 'account/:userId',
            name: 'orgUserAccount',
            component: () => import('./views/ModalAccount'),
            props: true,
            meta: {
              perimeter: organizationPerimeter,
              perimeterAction: 'read'
            }
          },
          {
            path: 'password/:userId',
            name: 'orgUserPassword',
            component: () => import('./views/ModalUserPassword'),
            props: true,
            meta: {
              perimeter: organizationPerimeter,
              perimeterAction: 'read'
            }
          }
        ]
      }
    ]
  },
  {
    path: '/system/organizations',
    props: { nav: 'system' },
    component: () => import('@argon/app/components/AppLayout'),
    children: [
      {
        path: '',
        name: 'systemOrganizations',
        component: () => import('@argon/iam/organizations/views/SystemOrganizations'),
        meta: {
          governess: OrganizationGoverness,
          perimeter: organizationPerimeter,
          perimeterAction: 'administrate'
        }
      }
    ]
  },
  {
    path: '/system',
    redirect: '/system/organizations'
  }
]
