import { axios, AND_SEPARATOR, EQ_OPERATOR } from '@argon/app/axios'

const NAMESPACE = 'advisors'

const state = {
  advisors: []
}

const mutations = {
  SET_AGENTS: (state, payload) => {
    state.advisors = payload
  }
}
const actions = {
  listAdvisors: ({ commit }, payload) => {
    const { filters, pager } = payload
    const extra = `active:${EQ_OPERATOR}:true`
    let filterParams = filters ? [filters, extra].join(AND_SEPARATOR) : extra
    let pagerParams = pager || {}
    return new Promise((resolve, reject) => {
      axios({
        url: '/groups/simpl-advisors/users',
        method: 'GET',
        params: { ...pagerParams, filters: filterParams, include: 'user,account' }
      })
        .then(({ data }) => {
          commit('SET_AGENTS', data.items)
          resolve(data.items)
        })
        .catch(reject)
    })
  }
}
const getters = {
  advisors: (state) => state.advisors
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}

export { NAMESPACE }
