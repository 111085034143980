<template>
  <VWidget content-class="pa-0 contract">
    <template v-slot:title>{{ $t('contract') }}</template>
    <template v-slot:header-actions v-if="!readOnly">
      <v-btn icon small @click="generate">
        <v-icon small>{{ $vuetify.icons.values.reset }}</v-icon>
      </v-btn>
      <v-btn icon small class="py-0 px-0" :loading="sendLoading" @click="sendContract">
        <v-icon small>{{ $vuetify.icons.values.send }}</v-icon>
      </v-btn>
    </template>
    <template v-slot:content>
      <FormErrors :show="showResourceErrors" :errors="resourceErrors" />
      <v-data-table hide-default-footer hide-default-header :headers="headers" :items="contracts" :loading="loading">
        <template v-slot:[`item.icon`]="{ item }">
          <v-icon small v-text="$vuetify.icons.values[item.file.extension] || $vuetify.icons.values.file" />
        </template>
        <template v-slot:[`item.text`]="{ item }">
          {{ item.file.display }}
          <div class="text-caption grey--text">{{ transformDate(item.created_on) }}</div>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <DownloadBtn :file="item.file" />
        </template>
      </v-data-table>
    </template>
  </VWidget>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import { formatDT } from '@argon/app/helpers'
import { BaseForm, FormErrors, VWidget, DownloadBtn } from '@argon/app/components'
import { NAMESPACE as ME_NAMESPACE } from '@argon/iam/me/store'
import { NAMESPACE } from '../store'
import policyPerimeter from '../perimeters'

export default {
  name: 'VContract',
  extends: BaseForm,
  perimeters: [policyPerimeter],
  components: { VWidget, FormErrors, DownloadBtn },
  props: {
    dealId: { type: [String, Number], required: true },
    readOnly: { type: Boolean, default: false }
  },
  data: () => ({
    loading: true,
    sendLoading: false,
    headers: [
      { text: '', value: 'icon' },
      { text: '', value: 'text' },
      { text: '', value: 'actions', width: 30, align: 'right' }
    ]
  }),
  computed: {
    ...mapState(NAMESPACE, ['contracts']),
    ...mapGetters(ME_NAMESPACE, ['timezone', 'locale'])
  },
  methods: {
    ...mapActions(NAMESPACE, ['listContracts', 'generateContract', 'sendContractFile']),
    transformDate(value) {
      return value ? formatDT(value, { format: 'LLL', tz: this.timezone, locale: this.locale }) : ''
    },
    generate() {
      this.loading = true
      this.resetServerErrors()
      this.generateContract(this.dealId)
        .catch(this.setServerErrors)
        .then(() => {
          this.loading = false
        })
    },
    sendContract() {
      this.sendLoading = true
      this.resetServerErrors()
      this.sendContractFile(this.dealId)
        .catch(this.setServerErrors)
        .then(() => {
          this.sendLoading = false
        })
    }
  },
  created() {
    this.listContracts(this.dealId).then(() => {
      this.loading = false
    })
  }
}
</script>
<style>
.contract .v-alert.subheading {
  margin: 0 !important;
}
</style>

<i18n>
{
  "en": {
    "contract": "Contract"
  },
  "fr": {
    "contract": "Contrat"
  }
}
</i18n>
