import { registerModule } from '@argon/app/helpers'
import store, { NAMESPACE } from './store'
import routes from './router'

const navItems = {
  organizations: [
    {
      name: 'leadHeader',
      weight: 10,
      component: () => import('./components/LeadHeader')
    },
    {
      name: 'quickLead',
      weight: 11,
      component: () => import('./components/NewIndividualLead')
    },
    {
      name: 'leads',
      weight: 12,
      component: () => import('./components/LeadsNavItem')
    }
  ]
}

const VuePlugin = {
  install: (Vue, options) => {
    registerModule(options.router, options.store, options.i18n, {
      navItems: navItems,
      namespace: NAMESPACE,
      routes: routes,
      namespaceStore: store
    })
  }
}

export default VuePlugin
