export default [
  {
    path: '/clients',
    component: () => import('@argon/app/components/AppLayout'),
    props: { nav: 'organizations' },
    children: [
      {
        path: '',
        name: 'clients',
        component: () => import('./views/Clients'),
        props: true,
        meta: {
          perimeterAction: 'read'
        },
        children: [
          {
            path: 'new',
            name: 'newClient',
            component: () => import('./views/ModalClient'),
            props: true,
            meta: {
              perimeterAction: 'read'
            }
          }
        ]
      },
      {
        path: ':clientId',
        name: 'clientPage',
        component: () => import('./views/ClientPage'),
        props: true,
        meta: {
          perimeterAction: 'read'
        },
        children: [
          {
            path: 'edit',
            name: 'editClient',
            component: () => import('./views/ModalClient'),
            props: true,
            meta: {
              perimeterAction: 'read'
            }
          }
        ]
      }
    ]
  }
]
