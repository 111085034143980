<template>
  <v-list class="pa-0 ma-0">
    <v-subheader class="subheading black--text">{{ policy.client.display }}</v-subheader>
    <v-divider />
    <v-list-item>
      <v-list-item-content>
        <v-list-item-subtitle>{{ $t('labels.certificateNumber') }}</v-list-item-subtitle>
        <v-list-item-title>{{ policy.certificate_number }}</v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <v-divider />
    <v-list-item>
      <v-list-item-content>
        <v-list-item-subtitle>{{ $t('labels.policyNumber') }}</v-list-item-subtitle>
        <v-list-item-title>{{ policy.policy_number }}</v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <v-divider />
    <v-list-item>
      <v-list-item-content>
        <v-list-item-subtitle>{{ $t('labels.insurer') }}</v-list-item-subtitle>
        <v-list-item-title>{{ policy.insurer }}</v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <v-divider />
    <v-list-item>
      <v-list-item-content>
        <v-list-item-subtitle>{{ $t('labels.loanAmount') }}</v-list-item-subtitle>
        <v-list-item-title>{{ toCurrency(policy.insured_loan_amount) }}</v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <v-divider />
    <v-list-item>
      <v-list-item-content>
        <v-list-item-subtitle>{{ $t('labels.insuredMonthlyPaymentAmount') }}</v-list-item-subtitle>
        <v-list-item-title class="error--text" v-if="policy.disability_is_capped">
          {{ toCurrency(policy.insured_monthly_payment_amount) }}
          <span>({{ toCurrency(policy.initial_insured_monthly_payment_amount) }})</span>
        </v-list-item-title>
        <v-list-item-title v-else>
          {{ toCurrency(policy.insured_monthly_payment_amount) }}
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <v-divider />
    <v-list-item>
      <v-list-item-content>
        <v-list-item-subtitle>{{ $t('labels.monthlyFee') }}</v-list-item-subtitle>
        <v-list-item-title>{{ toCurrency(policy.meta.premiums.monthly_fee) }}</v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <v-divider />
    <v-list-item>
      <v-list-item-content>
        <v-list-item-subtitle>{{ $t('labels.totalFee') }}</v-list-item-subtitle>
        <v-list-item-title>{{ toCurrency(policy.meta.premiums.total_fee) }}</v-list-item-title>
      </v-list-item-content>
    </v-list-item>
  </v-list>
</template>

<script>
import { InsuranceInfoMixin } from '../mixins'

export default {
  name: 'IAGPolicySummary',
  mixins: [InsuranceInfoMixin]
}
</script>

<style scoped lang="scss">
.subheading {
  background-color: #fafafa;
  font-size: $font-size-root;
}
</style>
