<template>
  <v-list dense subheader>
    <v-list-item>
      <v-list-item-subtitle>{{ $t('labels.acceptanceDate') }}</v-list-item-subtitle>
      <v-list-item-title class="text-right">{{ transformDate(product.meta.dates.acceptance_date) }}</v-list-item-title>
    </v-list-item>
    <v-divider></v-divider>
    <v-list-item v-if="isLife">
      <v-list-item-subtitle>{{ $t('labels.freeStartDate') }}</v-list-item-subtitle>
      <v-list-item-title class="text-right">{{ transformDate(product.meta.dates.free_start_date) }}</v-list-item-title>
    </v-list-item>
    <v-divider v-if="isLife" />
    <v-list-item v-if="isLife">
      <v-list-item-subtitle>{{ $t('labels.freeEndDate') }}</v-list-item-subtitle>
      <v-list-item-title class="text-right">{{ transformDate(product.meta.dates.free_end_date) }}</v-list-item-title>
    </v-list-item>
    <v-divider v-if="isLife" />
    <v-list-item>
      <v-list-item-subtitle>{{ $t('labels.premiumsStartDate') }}</v-list-item-subtitle>
      <v-list-item-title class="text-right">{{
        transformDate(product.meta.dates.premiums_start_date)
      }}</v-list-item-title>
    </v-list-item>
    <v-divider v-if="product.meta.dates.ongoing_bought_on" />
    <v-list-item v-if="product.meta.dates.ongoing_bought_on">
      <v-list-item-subtitle>{{ $t('labels.ongoingBoughtOn') }}</v-list-item-subtitle>
      <v-list-item-title class="text-right">{{
        transformDate(product.meta.dates.ongoing_bought_on)
      }}</v-list-item-title>
    </v-list-item>
    <v-divider v-if="product.meta.dates.finalized_delivered_on" />
    <v-list-item v-if="product.meta.dates.finalized_delivered_on">
      <v-list-item-subtitle>{{ $t('labels.finalizedDeliveredOn') }}</v-list-item-subtitle>
      <v-list-item-title class="text-right">{{
        transformDate(product.meta.dates.finalized_delivered_on)
      }}</v-list-item-title>
    </v-list-item>
  </v-list>
</template>

<script>
import { mapGetters } from 'vuex'
import { NAMESPACE as ME_NAMESPACE } from '@argon/iam/me/store'
import { formatDT } from '@argon/app/helpers'

export default {
  name: 'VProductDates',
  props: {
    utc: { type: Boolean, default: false },
    product: { type: Object, required: true }
  },
  computed: {
    ...mapGetters(ME_NAMESPACE, ['timezone', 'locale']),
    usedTimezone() {
      return this.utc ? 'UTC' : this.timezone
    },
    isLife() {
      return this.product.category === 'coverage' && this.product.type !== 'disability'
    }
  },
  methods: {
    transformDate(value) {
      return value ? formatDT(value, { format: 'LL', tz: this.usedTimezone, locale: this.locale }) : ''
    }
  }
}
</script>
