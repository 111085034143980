import { registerModule } from '@argon/app/helpers'

import routes from './router'
import store, { NAMESPACE } from './store'

const navItems = {
  main: [
    {
      name: 'overview',
      weight: 0,
      component: () => import('./components/OverviewNavItem')
    }
  ],
  organizations: [
    {
      name: 'orgOverview',
      weight: 2,
      component: () => import('./components/OrgOverviewNavItem')
    }
  ]
}

const VuePlugin = {
  install: (Vue, options) => {
    registerModule(options.router, options.store, options.i18n, {
      namespace: NAMESPACE,
      routes: routes,
      namespaceStore: store,
      navItems: navItems
    })
  }
}

export default VuePlugin
