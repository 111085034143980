<template>
  <v-text-field
    v-model.trim="localValue"
    :label="label"
    :error-messages="errors"
    suffix="CA$"
    @input="doTouch"
    @blur="doTouch"
    :readonly="readonly"
  />
</template>

<script>
import { CustomFieldMixin } from '@argon/app/mixins'

export default {
  name: 'CurrencyField',
  mixins: [CustomFieldMixin],
  props: {
    label: String,
    hint: String,
    errord: { type: Array, default: () => [] },
    touch: Function,
    readonly: Boolean
  },

  methods: {
    doTouch(v) {
      if (this.touch) {
        this.touch()
      }
    }
  }
}
</script>

<style></style>
