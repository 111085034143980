<template>
  <v-row align="baseline">
    <v-col cols="11">
      <v-text-field
        v-model="street_address"
        :placeholder="this.$t('streetAddress')"
        single-line
        :error-messages="streetAddressErrors"
        @input="$v.street_address.$touch()"
        @blur="$v.street_address.$touch()"
        prepend-icon="$vuetify.icons.values.organization"
        class="mb-0 pt-0"
      />
    </v-col>
    <v-col cols="1">
      <v-btn small icon @click="removeOne(idx)">
        <v-icon small>{{ $vuetify.icons.values.close }}</v-icon>
      </v-btn>
    </v-col>
    <v-col cols="8">
      <v-text-field
        v-model="city"
        :placeholder="this.$t('city')"
        single-line
        class="padding-left mb-0 pt-0"
        :error-messages="cityErrors"
        @input="$v.city.$touch()"
        @blur="$v.city.$touch()"
      />
    </v-col>
    <v-col cols="3">
      <v-select
        v-model="addressType"
        placeholder="Type"
        :items="types"
        item-text="display"
        item-value="value"
        single-line
        class="mb-0 pt-0"
      />
    </v-col>
    <v-col cols="1">&nbsp;</v-col>
    <v-col cols="4">
      <CountryField
        default="CA"
        v-model="country_code"
        :errors="countryCodeErrors"
        field-class="padding-left mb-0 pt-0"
      />
    </v-col>
    <v-col cols="4">
      <province-field default="QC" v-model="province" :country-code="country_code" field-class="mb-0 pt-0" />
    </v-col>
    <v-col cols="3">
      <v-text-field
        v-model="postal_code"
        :placeholder="this.$t('postalCode')"
        :error-messages="postalCodeErrors"
        @input="$v.postal_code.$touch()"
        @blur="$v.postal_code.$touch()"
        single-line
        class="mb-0 pt-0"
      />
    </v-col>
    <v-col cols="1">&nbsp;</v-col>
  </v-row>
</template>

<script>
import { CountryField, ProvinceField } from '@argon/app/components'
import { required } from 'vuelidate/lib/validators'

export default {
  name: 'AddressLine',
  components: { CountryField, ProvinceField },
  validations: {
    street_address: { required },
    country_code: { required },
    postal_code: { required },
    city: { required }
  },
  data: function () {
    return {
      types: [
        { value: 'current', display: this.$t('current') },
        { value: 'secondary', display: this.$t('secondary') },
        { value: 'other', display: this.$t('other') }
      ]
    }
  },
  props: {
    idx: Number,
    required: { type: Boolean, default: false },
    address: Object,
    removeOne: Function,
    update: Function,
    setParentError: Function,
    clearParentError: Function
  },
  computed: {
    province: {
      get() {
        return this.address.province
      },
      set(value) {
        this.update(this.idx, 'province', value)
      }
    },
    city: {
      get() {
        return this.address.city
      },
      set(value) {
        this.update(this.idx, 'city', value)
      }
    },
    postal_code: {
      get() {
        return this.address.postal_code
      },
      set(value) {
        this.update(this.idx, 'postal_code', value)
      }
    },
    country_code: {
      get() {
        return this.address.country_code
      },
      set(value) {
        this.update(this.idx, 'country_code', value)
      }
    },
    street_address: {
      get() {
        return this.address.street_address
      },
      set(value) {
        this.update(this.idx, 'street_address', value)
      }
    },
    addressType: {
      get() {
        return this.address.type
      },
      set(value) {
        this.update(this.idx, 'type', value)
      }
    },
    streetAddressErrors() {
      const errors = []
      if (!this.$v.street_address.$dirty) return errors
      !this.$v.street_address.required && errors.push(this.$t('labels.required'))
      return errors
    },
    cityErrors() {
      const errors = []
      if (!this.$v.city.$dirty) return errors
      !this.$v.city.required && errors.push(this.$t('labels.required'))
      return errors
    },
    countryCodeErrors() {
      const errors = []
      if (!this.$v.country_code.$dirty) return errors
      !this.$v.country_code.required && errors.push(this.$t('labels.required'))
      return errors
    },
    postalCodeErrors() {
      const errors = []
      if (!this.$v.postal_code.$dirty) return errors
      !this.$v.postal_code.required && errors.push(this.$t('labels.required'))
      return errors
    }
  },
  watch: {
    '$v.street_address.$error': function _watch$vStreetAddress$invalid(v) {
      this.setErrors()
    },
    '$v.city.$error': function _watch$vCity$invalid(v) {
      this.setErrors()
    }
  },
  methods: {
    setErrors() {
      if (this.$v.city.$error || this.$v.street_address.$error) {
        this.setParentError(this.idx)
      } else {
        this.clearParentError(this.idx)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.padding-left {
  padding-left: 32px;
}
</style>

<i18n>
{
  "en": {
    "current": "Current",
    "other": "Other",
    "city": "City",
    "postalCode": "Postal Code",
    "secondary": "Secondary",
    "streetAddress": "Street Address"
  },
  "fr": {
    "current": "Courante",
    "other": "Autre",
    "city": "Ville",
    "postalCode": "Code postal",
    "secondary": "Secondaire",
    "streetAddress": "Nom de rue"
  }
}
</i18n>
