<template>
  <v-select
    v-model="localValue"
    :items="localRefusals"
    item-text="name"
    item-value="value"
    hide-no-data
    :label="$t('labels.refusal')"
    required
    :error-messages="errors"
    :menu-props="{ maxWidth: '316px', closeOnContentClick: true }"
  />
</template>

<script>
import { CustomFieldMixin } from '@argon/app/mixins'
import FetchMixin from './Fetch'

export default {
  name: 'RefusalField',
  mixins: [FetchMixin, CustomFieldMixin]
}
</script>

<style lang="scss" scoped>
>>> .v-list__tile {
  height: 72px !important;
  align-items: top !important;
}

.v-list__tile__sub-title {
  white-space: normal !important;
}
</style>
