import userPerimeter from '@argon/iam/users/perimeters'
import UserGoverness from '@argon/iam/users/governesses'

export default [
  {
    path: '/system/users',
    component: () => import('@argon/app/components/AppLayout'),
    props: { nav: 'main' },
    children: [
      {
        path: '',
        name: 'systemUsers',
        component: () => import('./views/SystemUsers'),
        meta: {
          governess: UserGoverness,
          perimeter: userPerimeter,
          perimeterAction: 'read'
        },
        children: [
          {
            path: 'new',
            name: 'systemNewUser',
            component: () => import('./views/ModalNewUser'),
            props: true,
            meta: {
              governess: UserGoverness,
              perimeter: userPerimeter,
              perimeterAction: 'read'
            }
          },
          {
            path: 'profile/:userId',
            name: 'systemUserProfile',
            component: () => import('./views/ModalProfile'),
            props: true,
            meta: {
              governess: UserGoverness,
              perimeter: userPerimeter,
              perimeterAction: 'read'
            }
          },
          {
            path: 'account/:userId',
            name: 'systemUserAccount',
            component: () => import('@argon/iam/users/views/ModalAccount'),
            props: true,
            meta: {
              governess: UserGoverness,
              perimeter: userPerimeter,
              perimeterAction: 'read'
            }
          },
          {
            path: 'password/:userId',
            name: 'systemUserPassword',
            component: () => import('@argon/iam/users/views/ModalUserPassword'),
            props: true,
            meta: {
              governess: UserGoverness,
              perimeter: userPerimeter,
              perimeterAction: 'read'
            }
          }
        ]
      }
    ]
  }
]
