<template>
  <VWidget content-class="pa-0">
    <template v-slot:title>{{ $t('labels.policyInfo') }}</template>
    <template v-slot:header-actions>
      <v-chip small>{{ policy.insurer }}</v-chip>
    </template>
    <template v-slot:content>
      <v-list subheader>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-subtitle>{{ $t('labels.certificateNumber') }}</v-list-item-subtitle>
            <v-list-item-title>{{ policy.certificate_number }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider />
        <v-list-item>
          <v-list-item-content>
            <v-list-item-subtitle>{{ $t('labels.policyNumber') }}</v-list-item-subtitle>
            <v-list-item-title>{{ policy.policy_number }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider />
        <v-list-item>
          <v-list-item-content>
            <v-list-item-subtitle>{{ $t('labels.loanAmount') }}</v-list-item-subtitle>
            <v-list-item-title>{{ toCurrency(policy.insured_loan_amount) }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider />
        <v-list-item>
          <v-list-item-content>
            <v-list-item-subtitle>{{ $t('labels.insuredMonthlyPaymentAmount') }}</v-list-item-subtitle>
            <v-list-item-title>{{ toCurrency(policy.insured_monthly_payment_amount) }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider />
        <v-list-item>
          <v-list-item-content>
            <v-list-item-subtitle>{{ $t('labels.monthlyFee') }}</v-list-item-subtitle>
            <v-list-item-title>{{ toCurrency(policy.meta.premiums.monthly_fee) }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider />
        <v-list-item>
          <v-list-item-content>
            <v-list-item-subtitle>{{ $t('labels.totalFee') }}</v-list-item-subtitle>
            <v-list-item-title>{{ toCurrency(policy.meta.premiums.total_fee) }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </template>
  </VWidget>
</template>

<script>
import { VWidget } from '@argon/app/components'
import { InsuranceInfoMixin } from '../mixins'

export default {
  name: 'CLAPolicyInfo',
  components: { VWidget },
  mixins: [InsuranceInfoMixin]
}
</script>
