import { HeadGoverness } from 'vue-kindergarten'

export default class GeneralGoverness extends HeadGoverness {
  guard(action, { next }) {
    switch (action) {
      default:
        return this.isAllowed(action) ? next() : next({ name: 'me' })
    }
  }
}
