import { axios } from '@argon/app/axios'
import { toFilterString, stampItem, stampItems } from '@argon/app/helpers'
import { reject as _reject } from 'lodash/collection'

const NAMESPACE = 'firms'

const state = {
  firms: [],
  filterObject: null,
  firm: null,
  agencies: [],
  tableItemsCount: null,
  tablePagination: {}
}

const mutations = {
  SET_FIRMS: (state, payload) => {
    state.firms = payload
  },
  SET_FILTERS: (state, payload) => {
    state.filterObject = payload
  },
  SET_FIRM: (state, payload) => {
    state.firm = payload
  },
  SET_AGENCIES: (state, payload) => {
    state.agencies = payload
  },
  SET_AGENCIES_COUNT: (state, payload) => {
    state.agenciesCount = payload
  },
  SET_TABLE_PAGINATION: (state, payload) => {
    state.tablePagination = payload
  },
  SET_TABLE_ITEMS_COUNT: (state, payload) => {
    state.tableItemsCount = payload
  }
}
const actions = {
  setFilters: ({ commit }, filterObject) => {
    commit('SET_FILTERS', stampItem(filterObject))
  },
  listFirms: ({ commit, state, rootState }, { pager }) => {
    const filters = toFilterString(rootState.app.appliedFilters[NAMESPACE])
    let pagerParams = pager || state.tablePagination
    let filterParams = filters ? { filters } : {}
    let params = { ...filterParams, ...pagerParams }
    return new Promise((resolve, reject) => {
      axios({
        url: '/firms',
        method: 'GET',
        params
      })
        .then(({ data }) => {
          commit('SET_FIRMS', stampItems(data.items))
          commit('SET_TABLE_ITEMS_COUNT', data.count)
          commit('SET_TABLE_PAGINATION', pagerParams)
          resolve(data.items)
        })
        .catch(reject)
    })
  },
  listReadOnlyFirms: ({ commit }) => {
    return new Promise((resolve, reject) => {
      axios({
        url: '/read/firms',
        method: 'GET',
        params: { per_page: -1, filters: 'public:eq:true' }
      })
        .then(({ data }) => {
          commit('SET_FIRMS', data.items)
          resolve(data.items)
        })
        .catch(reject)
    })
  },
  clearFirms: ({ commit }) => {
    return new Promise((resolve, reject) => {
      commit('SET_FIRMS', [])
      commit('SET_FIRM', null)
      resolve(true)
    })
  },
  getFirm: ({ commit }, firmId) => {
    return new Promise((resolve, reject) => {
      axios({
        url: `/firms/${firmId}`,
        method: 'GET'
      })
        .then(({ data }) => {
          commit('SET_FIRM', data)
          resolve(data)
        })
        .catch(reject)
    })
  },
  clearFirm: ({ commit }) => {
    return new Promise((resolve, reject) => {
      commit('SET_FIRM', null)
      resolve(true)
    })
  },
  getFirmAgencies: ({ commit }, firmId) => {
    return new Promise((resolve, reject) => {
      axios({
        url: `/firms/${firmId}/agencies`,
        params: { per_page: -1 },
        method: 'GET'
      })
        .then(({ data }) => {
          commit('SET_AGENCIES', data.items)
          commit('SET_AGENCIES_COUNT', data.count)
          resolve(data.items)
        })
        .catch(reject)
    })
  },
  getReadonOnlyFirmAgencies: ({ commit }, firmId) => {
    return new Promise((resolve, reject) => {
      if (!firmId) {
        resolve(true)
      } else {
        axios({
          url: `/read/firms/${firmId}/agencies`,
          params: { per_page: -1 },
          method: 'GET'
        })
          .then(({ data }) => {
            commit('SET_AGENCIES', data.items)
            commit('SET_AGENCIES_COUNT', data.count)
            resolve(data.items)
          })
          .catch(reject)
      }
    })
  },
  clearFirmAgencies: ({ commit }) => {
    return new Promise((resolve, reject) => {
      commit('SET_AGENCIES', [])
      resolve(true)
    })
  },
  patchFirm: ({ commit }, { data, firmId }) => {
    return new Promise((resolve, reject) => {
      axios({
        url: `/firms/${firmId}`,
        data,
        method: 'PATCH'
      })
        .then(({ data }) => {
          commit('SET_FIRM', data)
          resolve(data)
        })
        .catch(reject)
    })
  },

  createFirm: ({ commit, state }, data) => {
    let url = '/firms'
    return new Promise((resolve, reject) => {
      axios({
        url,
        data,
        method: 'POST'
      })
        .then(({ data }) => {
          commit('SET_FIRMS', [...state.firms, data])
          resolve(data)
        })
        .catch(reject)
    })
  },
  deleteFirm: ({ commit, state }, firm) => {
    let url = `/firms/${firm.id}`
    return new Promise((resolve, reject) => {
      axios({
        url,
        method: 'DELETE'
      })
        .then(() => {
          commit(
            'SET_FIRMS',
            state.firms.filter((item) => item.id !== firm.id)
          )
          resolve(true)
        })
        .catch(reject)
    })
  },
  getAgency: ({}, { firmId, agencyId }) => {
    let url = `/firms/${firmId}/agencies/${agencyId}`
    return new Promise((resolve, reject) => {
      axios({ url, method: 'GET' })
        .then(({ data }) => {
          resolve(data)
        })
        .catch(reject)
    })
  },

  patchAgency: ({ dispatch }, { data, firmId, agencyId }) => {
    let url = `/firms/${firmId}/agencies/${agencyId}`
    return new Promise((resolve, reject) => {
      axios({ url, data, method: 'PATCH' })
        .then(() => {
          return dispatch('getFirmAgencies', firmId)
        })
        .then(resolve)
        .catch(reject)
    })
  },

  createAgency: ({ dispatch }, { data, firmId }) => {
    let url = `/firms/${firmId}/agencies`
    return new Promise((resolve, reject) => {
      axios({ url, data, method: 'POST' })
        .then(() => {
          return dispatch('getFirmAgencies', firmId)
        })
        .then(resolve)
        .catch(reject)
    })
  },
  deleteAgency: ({ dispatch }, { firmId, agencyId }) => {
    let url = `/firms/${firm.id}/agencies/${agencyId}`
    return new Promise((resolve, reject) => {
      axios({ url, method: 'DELETE' })
        .then(() => {
          return dispatch('getFirmAgencies', firmId)
        })
        .then(resolve)
        .catch(reject)
    })
  }
}
const getters = {
  firms: (state) => state.firms,
  firm: (state) => state.firm,
  agencies: (state) => state.agencies,
  getFirmById: (state) => (id) => state.firms.find((item) => item.id === parseInt(id))
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}

export { NAMESPACE }
