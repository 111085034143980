<template>
  <v-tabs>
    <v-toolbar dense color="transparent">
      <v-tabs v-model="activeTab">
        <v-tab ripple v-for="(policy, idx) in sortedPolicies" :key="`insurance-${idx}-tab`">{{
          policy.client.display
        }}</v-tab>
      </v-tabs>
      <v-spacer />
      <v-tooltip top transition="fade-transition" v-if="!readOnly">
        <template v-slot:activator="{ on }">
          <v-btn icon small @click="doRecompute" :loading="loading" v-on="on">
            <v-icon small>{{ $vuetify.icons.values.reset }}</v-icon>
          </v-btn>
        </template>
        <span>{{ $t('recompute') }}</span>
      </v-tooltip>
    </v-toolbar>
    <v-tabs-items v-model="activeTab">
      <v-tab-item v-for="(policy, idx) in sortedPolicies" :key="`insurance-${idx}-content`" class="mt-4">
        <applicant-insurance :policy="policy" :read-only="locked" />
      </v-tab-item>
    </v-tabs-items>
  </v-tabs>
</template>

<script>
import { mapActions } from 'vuex'
import ApplicantInsurance from './ApplicantInsurance'
import { InsuranceMixin } from '../mixins'
import { NAMESPACE } from '../store'

export default {
  name: 'EntityInsurance',
  mixins: [InsuranceMixin],
  components: {
    ApplicantInsurance
  },
  props: {},
  data: () => ({
    activeTab: 0,
    loading: false
  }),
  methods: {
    ...mapActions(NAMESPACE, ['recomputePremiums']),
    doRecompute() {
      this.loading = true
      this.recomputePremiums(this.dealId).finally(() => {
        this.loading = false
      })
    }
  }
}
</script>

<i18n>
{
  "en": {
    "recompute": "Recompute premiums"
  },
  "fr": {
    "recompute": "Recalculer les primes"
  }
}
</i18n>
