<template>
  <VWidget content-class="pa-0" enable-footer-actions>
    <template v-slot:title>{{ $t('labels.products') }}</template>
    <template v-slot:header-actions v-if="canEdit">
      <v-btn icon small @click="create">
        <v-icon small>{{ $vuetify.icons.values.plus }}</v-icon>
      </v-btn>
    </template>
    <template v-slot:content>
      <v-data-table :headers="headers" :items="products" show-expand single-expand hide-default-footer>
        <template v-slot:item.display="{ item }">
          {{ $t(`labels.${item.type}`) }}
        </template>
        <template v-slot:item.fee="{ item }">
          {{ toCurrency(item.monthly_fee) }}
        </template>

        <template v-slot:item.actions="{ item }" v-if="canEdit">
          <v-btn icon small @click.stop="edit(item)" v-if="item.category !== 'discount'">
            <v-icon small>{{ $vuetify.icons.values.edit }}</v-icon>
          </v-btn>
          <v-btn icon small @click.stop="doDeleteProduct(item)">
            <v-icon small>{{ $vuetify.icons.values.trash }}</v-icon>
          </v-btn>
        </template>

        <template v-slot:expanded-item="{ item }">
          <td :colspan="headers.length" class="pa-0">
            <v-product-dates utc :product="item" v-if="item.meta.dates" />
          </td>
        </template>
      </v-data-table>
    </template>
    <template slot="footer-actions">
      <div class="text-body-2">
        <span class="lighten-3 mr-1">{{ $t('labels.monthlyFee') }}:</span>
        <span class="font-weight-bold">{{ toCurrency(policy.meta.premiums.monthly_fee) }}</span>
        <span class="ml-2 mr-2">•</span>
        <span class="lighten-3 mr-1">{{ $t('labels.totalFee') }}:</span>
        <span class="font-weight-bold">{{ toCurrency(policy.meta.premiums.total_fee) }}</span>
      </div>
    </template>
  </VWidget>
</template>

<script>
import { mapGetters, mapState, mapActions } from 'vuex'
import { VWidget } from '@argon/app/components'
import { NAMESPACE as ME_NAMESPACE } from '@argon/iam/me/store'
import { money } from '../../utils'
import { NAMESPACE } from '../store'
import policyPerimeter from '../perimeters'
import { VProductDates } from '../dates'

export default {
  name: 'VProducts',
  perimeters: [policyPerimeter],
  components: { VWidget, VProductDates },
  data() {
    return {
      headers: [
        {
          text: this.$t('labels.name'),
          align: 'left',
          sortable: false,
          value: 'display'
        },
        {
          text: this.$t('labels.category'),
          align: 'left',
          sortable: false,
          value: 'category'
        },
        {
          text: this.$t('labels.monthlyFee'),
          align: 'right',
          sortable: false,
          value: 'fee'
        },
        { text: '', value: 'actions', align: 'right', width: 100, sortable: false },
        { text: '', value: 'data-table-expand' }
      ]
    }
  },
  props: {
    policy: { type: Object, required: true },
    readOnly: { type: Boolean, default: false }
  },
  computed: {
    ...mapGetters(ME_NAMESPACE, ['locale']),
    products() {
      return this.policy.products
    },
    dealId() {
      return this.policy.deal_id
    },
    policyId() {
      return this.policy.id
    },
    canEdit() {
      return this.$policy.isAllowed('atLeastAdvisor') && !this.readOnly
    }
  },
  methods: {
    ...mapActions(NAMESPACE, ['deleteProduct']),
    toCurrency(v) {
      return money(v, this.locale)
    },
    create() {
      this.$router.push({
        name: 'nestedProduct',
        params: {
          ...this.$route.params,
          policyId: this.policyId,
          productId: 'new'
        }
      })
    },
    edit(product) {
      this.$router.push({
        name: 'nestedProduct',
        params: {
          ...this.$route.params,
          policyId: this.policyId,
          productId: product.id
        }
      })
    },
    doDeleteProduct(product) {
      let payload = {
        dealId: this.dealId,
        policyId: this.policyId,
        productId: product.id
      }
      this.deleteProduct(payload)
    }
  }
}
</script>
<style>
.v-table tbody td.v-datatable__expand-col {
  padding: 0 !important;
}
</style>
