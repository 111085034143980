import { axios, EQ_OPERATOR } from '@argon/app/axios'
import { omit } from 'lodash/object'

const NAMESPACE = 'leads'

const state = {
  revisions: [],
  linkedDeals: [],
  clients: [],
  notes: [],
  files: [],
  brokerId: null,
  advisorId: null,
  createdOn: null
}

const mutations = {
  SET_REVISIONS: (state, payload) => {
    state.revisions = payload
  },
  SET_LINKED_DEALS: (state, payload) => {
    state.linkedDeals = payload
  },
  SET_CLIENTS: (state, payload) => {
    state.clients = payload
  },
  SET_NOTES: (state, payload) => {
    state.notes = payload
  },
  SET_FILES: (state, payload) => {
    state.files = payload
  },
  SET_BROKER_ID: (state, payload) => {
    state.brokerId = payload
  },
  SET_ADVISOR_ID: (state, payload) => {
    state.advisorId = payload
  },
  SET_CREATED_ON: (state, payload) => {
    state.createdOn = payload
  }
}
const actions = {
  listRevisions: ({ commit }, leadId) => {
    return new Promise((resolve, reject) => {
      axios({
        url: `/leads/${leadId}/revisions`,
        params: { sort_by: 'created_on', sort_desc: true },
        method: 'GET'
      })
        .then(({ data }) => {
          commit('SET_REVISIONS', data.items)
          resolve(data.items)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  listLinkedDeals: ({ commit }, leadId) => {
    return new Promise((resolve, reject) => {
      axios({
        url: '/deals',
        params: { filters: `lead_id:${EQ_OPERATOR}:${leadId}`, include: 'board' },
        method: 'GET'
      })
        .then(({ data }) => {
          commit('SET_LINKED_DEALS', data.items)
          resolve(data.items)
        })
        .catch(reject)
    })
  },
  addClient: ({ commit, state }, client) => {
    return new Promise((resolve, reject) => {
      let clients = [...state.clients, client].map((x, idx) => ({ ...x, id: idx + 1 }))
      commit('SET_CLIENTS', clients)
      resolve(true)
    })
  },
  editClient: ({ commit, state }, payload) => {
    const { clientId, data } = payload
    return new Promise((resolve, reject) => {
      let clients = state.clients.map((x) => (x.id === clientId ? { ...data, id: clientId } : x))
      commit('SET_CLIENTS', clients)
      resolve(true)
    })
  },
  deleteClient: ({ commit, state }, idx) => {
    return new Promise((resolve, reject) => {
      let clients = state.clients.filter((x) => x.id !== idx)
      commit('SET_CLIENTS', clients)
      resolve(true)
    })
  },
  addNote: ({ commit, state }, note) => {
    return new Promise((resolve, reject) => {
      let notes = [...state.notes, note]
      commit('SET_NOTES', notes)
      resolve(true)
    })
  },

  deleteNote: ({ commit, state }, noteIdx) => {
    return new Promise((resolve, reject) => {
      let notes = state.notes.filter((x, idx) => idx !== noteIdx)
      commit('SET_NOTES', notes)
      resolve(true)
    })
  },
  addFile: ({ commit, dispatch, state }, formData) => {
    return new Promise((resolve, reject) => {
      axios({ url: '/files', data: formData, method: 'POST' })
        .then(({ data }) => {
          let files = [...state.files, { filename: data.filename, id: data.id }]
          commit('SET_FILES', files)
          resolve(true)
        })
        .catch(reject)
    })
  },
  deleteFile: ({ commit, dispatch, state }, fileId) => {
    return new Promise((resolve, reject) => {
      axios({ url: `/files/${fileId}`, method: 'DELETE' }).then(({ data }) => {
        let files = state.files.filter((x) => x.id !== fileId)
        commit('SET_FILES', files)
        resolve(true)
      })
    })
  },
  setBroker: ({ commit }, brokerId) => {
    return new Promise((resolve, reject) => {
      commit('SET_BROKER_ID', brokerId)
      resolve(true)
    })
  },
  setAdvisor: ({ commit }, advisorId) => {
    return new Promise((resolve, reject) => {
      commit('SET_ADVISOR_ID', advisorId)
      resolve(true)
    })
  },
  setCreatedOn: ({ commit }, createdOn) => {
    return new Promise((resolve, reject) => {
      commit('SET_CREATED_ON', createdOn)
      resolve(true)
    })
  },
  resetWizard: ({ commit }) => {
    commit('SET_CLIENTS', [])
    commit('SET_NOTES', [])
    commit('SET_FILES', [])
    commit('SET_BROKER_ID', null)
    commit('SET_ADVISOR_ID', null)
    commit('SET_CREATED_ON', null)
  },
  createIndividualLead: ({ dispatch, state }) => {
    let broker_id = state.brokerId
    let advisor_id = state.advisorId
    let applicants = state.clients.map((x) => omit(x, ['id']))
    return new Promise((resolve, reject) => {
      let data = {
        broker_id,
        advisor_id,
        applicants,
        notes: state.notes,
        files: state.files
      }
      if (state.createdOn) {
        data['created_on'] = state.createdOn
      }
      axios({
        url: '/leads',
        method: 'POST',
        data
      })
        .then(({ data }) => {
          dispatch('resetWizard')
          resolve(data)
        })
        .catch(reject)
    })
  },
  pullLead: ({}, leadId) => {
    return new Promise((resolve, reject) => {
      axios({
        url: `/leads/${leadId}/pull`,
        method: 'POST',
        data: {}
      })
        .then(() => {
          resolve(true)
        })
        .catch(reject)
    })
  },
  refreshLead: ({}, leadId) => {
    return new Promise((resolve, reject) => {
      axios({
        url: `/leads/${leadId}/refresh`,
        method: 'POST',
        data: {}
      })
        .then(() => {
          resolve(true)
        })
        .catch(reject)
    })
  }
}

const getters = {
  linkedDeals: (state) => state.linkedDeals
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}

export { NAMESPACE }
