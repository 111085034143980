import { every, orderBy } from 'lodash/collection'
import { mapState, mapActions } from 'vuex'
import { NAMESPACE } from '../store'
import { NAMESPACE as ENTITY_NAMESPACE } from '@argon/evo/entities/store'
import { NAMESPACE as REALTIME_NAMESPACE } from '@argon/realtime/store'

export default {
  name: 'InsuranceMixin',
  props: {
    dealId: { type: [Number, String], required: true },
    dealRefid: { type: [Number, String], required: true },
    readOnly: { type: Boolean, default: false }
  },
  computed: {
    ...mapState(ENTITY_NAMESPACE, ['boardItem']),
    ...mapState(NAMESPACE, ['policies']),
    sortedPolicies() {
      return orderBy(this.policies, ['is_main'], ['desc'])
    },
    hasGroupDiscount() {
      return this.policies.length > 0 && every(this.policies, 'has_group_discount')
    },
    locked() {
      return this.readOnly || [('delivered', 'paid', 'completed')].includes(this.boardItem.step)
    },
    realtimeKey() {
      return this.$store.getters[`${REALTIME_NAMESPACE}/realtimeKey`]({
        category: 'dealItems',
        identifier: this.dealRefid
      })
    }
  },
  methods: {
    ...mapActions(REALTIME_NAMESPACE, ['addListener', 'removeListener']),
    ...mapActions(NAMESPACE, ['listDealPolicies', 'clearDealPolicies']),
    fetchData() {
      return this.listDealPolicies(this.dealId)
    }
  },
  watch: {
    realtimeKey: function (v, oldV) {
      if (oldV) {
        this.fetchData()
      }
    }
  },
  created() {
    this.fetchData().then(() => {
      this.addListener({ category: 'dealItems', identifier: this.dealRefid })
    })
  },
  destroyed() {
    this.clearDealPolicies()
    this.removeListener({ category: 'dealItems', identifier: this.dealRefid })
  }
}
