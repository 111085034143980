import { axios, EQ_OPERATOR } from '@argon/app/axios'
import { stampItems } from '@argon/app/helpers'
import { orderBy } from 'lodash/collection'

const NAMESPACE = 'deals'

const state = {
  requiredCompliance: [],
  compliance: [],
  dealClients: [],
  complianceFiles: [],
  complianceFilesCount: null,
  notePresets: [],
  taskPresets: [],
  partnerFiles: [],
  complianceFile: null
}

const mutations = {
  SET_COMPLIANCE: (state, payload) => {
    state.compliance = payload
  },
  SET_REQUIRED_COMPLIANCE: (state, payload) => {
    state.requiredCompliance = payload
  },
  SET_PARTNER_FILES: (state, payload) => {
    state.partnerFiles = payload
  },
  SET_COMPLIANCE_FILES: (state, payload) => {
    state.complianceFiles = payload
  },
  SET_COMPLIANCE_FILES_COUNT: (state, payload) => {
    state.complianceFilesCount = payload
  },
  SET_NOTE_PRESETS: (state, payload) => {
    state.notePresets = payload
  },
  SET_TASK_PRESETS: (state, payload) => {
    state.taskPresets = payload
  },
  SET_COMPLIANCE_FILE: (state, payload) => {
    state.complianceFile = payload
  },
  SET_DEAL_CLIENTS: (state, payload) => {
    state.dealClients = payload
  }
}
const actions = {
  listComplianceTypes: ({ commit, dispatch }) => {
    return new Promise((resolve, reject) => {
      axios({
        url: '/deal-compliance-types',
        method: 'GET'
      })
        .then(({ data }) => {
          commit('SET_COMPLIANCE', stampItems(data.items))
          resolve(data.items)
        })
        .catch(reject)
    })
  },
  listRequiredComplianceFor: ({ commit }, dealId) => {
    return new Promise((resolve, reject) => {
      axios({
        url: `/deals/${dealId}/required-compliance`,
        method: 'GET'
      })
        .then(({ data }) => {
          commit('SET_REQUIRED_COMPLIANCE', stampItems(data.items))
          resolve(data.items)
        })
        .catch(reject)
    })
  },
  listNotePresets: ({ commit }) => {
    return new Promise((resolve, reject) => {
      axios({
        url: '/note-presets',
        params: { sort_by: 'display', filters: `active:${EQ_OPERATOR}:true` },
        method: 'GET'
      })
        .then(({ data }) => {
          commit('SET_NOTE_PRESETS', stampItems(data.items))
          resolve(data.items)
        })
        .catch(reject)
    })
  },
  listTaskPresets: ({ commit }) => {
    return new Promise((resolve, reject) => {
      axios({
        url: '/task-presets',
        method: 'GET'
      })
        .then(({ data }) => {
          commit('SET_TASK_PRESETS', stampItems(data.items))
          resolve(data.items)
        })
        .catch(reject)
    })
  },
  listPartnerFiles: ({ commit }, dealId) => {
    return new Promise((resolve, reject) => {
      axios({
        url: `/deals/${dealId}/partner-files`,
        params: { sort_by: 'created_on', sort_desc: true },
        method: 'GET'
      })
        .then(({ data }) => {
          commit('SET_PARTNER_FILES', stampItems(data.items))
          resolve(data.items)
        })
        .catch(reject)
    })
  },
  clearPartnerFiles: ({ commit }) => {
    return new Promise((resolve, reject) => {
      commit('SET_PARTNER_FILES', [])
      resolve(true)
    })
  },
  requestPartnerUpload: ({}, { dealId, data }) => {
    return new Promise((resolve, reject) => {
      axios({
        url: `/deals/${dealId}/partner-upload`,
        method: 'POST',
        data
      })
        .then(() => {
          resolve(true)
        })
        .catch(reject)
    })
  },
  listComplianceFiles: ({ commit }, { dealId, pager }) => {
    const _pager = pager || {}
    return new Promise((resolve, reject) => {
      axios({
        url: `/deals/${dealId}/compliance`,
        params: { include: 'client', ..._pager },
        method: 'GET'
      })
        .then(({ data }) => {
          commit('SET_COMPLIANCE_FILES', stampItems(data.items))
          commit('SET_COMPLIANCE_FILES_COUNT', data.count)
          resolve(data.items)
        })
        .catch(reject)
    })
  },
  clearComplianceFiles: ({ commit }) => {
    return new Promise((resolve, reject) => {
      commit('SET_COMPLIANCE_FILES', [])
      resolve(true)
    })
  },
  getComplianceFile: ({ commit }, payload) => {
    const { dealId, fileId } = payload
    return new Promise((resolve, reject) => {
      axios({
        url: `/deals/${dealId}/compliance/${fileId}`,
        method: 'GET'
      })
        .then(({ data }) => {
          commit('SET_COMPLIANCE_FILE', data)
          resolve(data)
        })
        .catch(reject)
    })
  },
  createComplianceFile: ({ dispatch }, { dealId, form }) => {
    return new Promise((resolve, reject) => {
      axios({
        url: `/deals/${dealId}/compliance`,
        method: 'POST',
        data: { ...form }
      })
        .then(({ data }) => {
          dispatch('listComplianceFiles', { dealId })
          resolve(data)
        })
        .catch(reject)
    })
  },
  patchComplianceFile: ({ dispatch }, payload) => {
    const { dealId, fileId, data } = payload
    return new Promise((resolve, reject) => {
      axios({
        url: `/deals/${dealId}/compliance/${fileId}`,
        method: 'PATCH',
        data
      })
        .then(({ data }) => {
          dispatch('listComplianceFiles', { dealId })
          resolve(data)
        })
        .catch(reject)
    })
  },
  deleteComplianceFile: ({ dispatch }, payload) => {
    const { dealId, fileId } = payload
    return new Promise((resolve, reject) => {
      axios({
        url: `/deals/${dealId}/compliance/${fileId}`,
        method: 'DELETE'
      })
        .then(() => {
          dispatch('listComplianceFiles', { dealId })
          resolve(true)
        })
        .catch(reject)
    })
  },
  createDealClient: ({}, { dealId, clientId }) => {
    return new Promise((resolve, reject) => {
      axios({
        url: `/deals/${dealId}/clients`,
        data: { client_id: clientId },
        method: 'POST'
      })
        .then(({ data }) => {
          resolve(data)
        })
        .catch(reject)
    })
  },
  listDealClients: ({ commit }, dealId) => {
    return new Promise((resolve, reject) => {
      axios({
        url: `/deals/${dealId}/clients`,
        params: { include: 'client,applicant' },
        method: 'GET'
      })
        .then(({ data }) => {
          let sortedItems = orderBy(data.items, ['is_main_provider_client'], ['desc'])
          commit('SET_DEAL_CLIENTS', stampItems(data.items))
          resolve(sortedItems)
        })
        .catch(reject)
    })
  },
  clearDealClients: ({ commit }) => {
    return new Promise((resolve, reject) => {
      commit('SET_DEAL_CLIENTS', [])
      resolve(true)
    })
  },
  patchDealClient: ({}, payload) => {
    const { dealId, clientId, data } = payload
    return new Promise((resolve, reject) => {
      axios({
        url: `/deals/${dealId}/clients/${clientId}`,
        data,
        method: 'PATCH'
      })
        .then(({ data }) => {
          resolve(data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  deleteDealClient: ({}, { dealId, clientId }) => {
    return new Promise((resolve, reject) => {
      axios({
        url: `/deals/${dealId}/clients/${clientId}`,
        method: 'DELETE'
      })
        .then(({}) => {
          resolve(true)
        })
        .catch(reject)
    })
  },
  getLead: ({}, leadId) => {
    return new Promise((resolve, reject) => {
      axios({
        url: `/leads/${leadId}`,
        method: 'get',
        params: { include: 'board' }
      })
        .then(({ data }) => {
          resolve(data)
        })
        .catch(reject)
    })
  },
  getDeal: ({}, { dealId, include }) => {
    return new Promise((resolve, reject) => {
      let params = include ? { include } : { include: 'lead,clients,broker,firm,property,mortgage,advisor' }
      axios({
        url: `/deals/${dealId}`,
        method: 'get',
        params
      })
        .then(({ data }) => {
          resolve(data)
        })
        .catch(reject)
    })
  },
  patchDeal: ({}, { dealId, data }) => {
    return new Promise((resolve, reject) => {
      axios({
        url: `/deals/${dealId}`,
        data,
        method: 'PATCH'
      })
        .then(({ data }) => {
          resolve(data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  setDealBroker: ({}, { dealId, data }) => {
    return new Promise((resolve, reject) => {
      axios({
        url: `/deals/${dealId}/broker`,
        data,
        method: 'POST'
      })
        .then(({ data }) => {
          resolve(data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  setDealAdvisor: ({}, { dealId, data }) => {
    return new Promise((resolve, reject) => {
      axios({
        url: `/deals/${dealId}/advisor`,
        data,
        method: 'POST'
      })
        .then(({ data }) => {
          resolve(data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  duplicateDeal: ({}, { dealId, individual, full }) => {
    return new Promise((resolve, reject) => {
      axios({
        url: `/deals/${dealId}/duplicate`,
        method: 'post',
        data: { individual, full }
      })
        .then(({ data }) => {
          resolve(data)
        })
        .catch(reject)
    })
  }
}
const getters = {
  complianceFiles: (state) => state.complianceFiles,
  complianceFile: (state) => state.complianceFile,
  compliance: (state) => state.compliance
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}

export { NAMESPACE }
