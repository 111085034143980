// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'

import { router } from '@argon/app/router'
import { store } from '@argon/app/store'
import { i18n } from '@argon/app/i18n'
import { registerMessages } from '@argon/app/helpers'

import AppPlugin, { installVuetify } from '@argon/app'
import IAMPlugin from '@argon/iam'
import EvoPlugin from '@argon/evo'
import RealtimePlugin from '@argon/realtime'

import { preset } from '@/preset'
import { App } from '@/components'

import AuthPlugin from '@/auth'
import UsersPlugin from '@/users'
import BoardsPlugin from '@/boards'
import OverviewPlugin from '@/overview'
import DealsPlugin from '@/deals'
import FlowsPlugin from '@/flows'
import LeadsPlugin from '@/leads'
import ClientsPlugin from '@/clients'
import TransactionsPlugin from '@/transactions'
import FirmsPlugin from '@/firms'
import BrokersPlugin from '@/brokers'
import AdvisorsPlugin from '@/advisors'
import MePlugin from './me'
import OrganizationsPlugin from './organizations'
import BlockersPlugin from './blockers'
import LogBookPlugin from './logbook'
import InsurancePlugin from './insurance'
import StatusesPlugin from './statuses'
import RefusalsPlugin from './refusals'
import initSentry from './sentry'

import './registerServiceWorker'

const appOpts = {
  title: 'SIMPL | IMS',
  showTitle: true,
  // colors
  headerColor: 'primary',
  // images
  logoSrc: '/img/icons/simpl-logo.png',
  authLogoSrc: '/img/icons/simpl-logo.png',
  headerLogoSrc: '' //'/img/icons/simpl-white-32x32.png'
}

const vuetifyOpts = {
  preset,
  i18n
}

const messages = {
  en: require('@/locale/en'),
  fr: require('@/locale/fr')
}

registerMessages(i18n, messages)

const vuetify = installVuetify(Vue, { i18n, appOpts, vuetifyOpts })
Vue.use(AppPlugin, { router, store, i18n, appOpts, vuetifyOpts })
Vue.use(IAMPlugin, { router, store, i18n })
Vue.use(AuthPlugin, { router, store, i18n })
Vue.use(UsersPlugin, { router, store, i18n })
Vue.use(EvoPlugin, { router, store, i18n })

Vue.use(OverviewPlugin, { router, store, i18n })
Vue.use(RealtimePlugin, { router, store, i18n })

Vue.use(FlowsPlugin, { router, store, i18n })
Vue.use(BoardsPlugin, { router, store, i18n })

Vue.use(DealsPlugin, { router, store, i18n })
Vue.use(LeadsPlugin, { router, store, i18n })
Vue.use(ClientsPlugin, { router, store, i18n })
Vue.use(FirmsPlugin, { router, store, i18n })
Vue.use(BrokersPlugin, { router, store, i18n })
Vue.use(AdvisorsPlugin, { router, store, i18n })
Vue.use(LogBookPlugin, { router, store, i18n })
Vue.use(InsurancePlugin, { router, store, i18n })
Vue.use(OrganizationsPlugin, { router, store, i18n })
Vue.use(MePlugin, { router, store, i18n })
Vue.use(BlockersPlugin, { router, store, i18n })
Vue.use(StatusesPlugin, { router, store, i18n })
Vue.use(RefusalsPlugin, { router, store, i18n })
Vue.use(BlockersPlugin, { router, store, i18n })
Vue.use(TransactionsPlugin, { router, store, i18n })

Vue.config.productionTip = false

initSentry(Vue, store)

/* eslint-disable no-new */
new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: (h) => h(App)
}).$mount('#app')
