import { registerModule } from '@argon/app/helpers'

import routes from './router'
import store, { NAMESPACE } from '@argon/iam/me/store'

const launcherItems = [
  {
    name: 'account',
    weight: 90,
    component: () => import('@argon/iam/me/components/AccountLauncherItem')
  }
]

const headerItems = [
  {
    name: 'iam-me',
    weight: 1,
    component: () => import('@argon/iam/me/components/MeHeaderItem')
  }
]

const navItems = {
  main: [
    {
      name: 'me',
      weight: 1,
      component: () => import('@argon/iam/me/components/MeNavItem')
    }
  ]
}

const VuePlugin = {
  install: (Vue, options) => {
    registerModule(options.router, options.store, options.i18n, {
      namespace: NAMESPACE,
      routes: routes,
      namespaceStore: store,
      // launcherItems: launcherItems,
      headerItems: headerItems,
      navItems: navItems
    })
  }
}

export default VuePlugin
