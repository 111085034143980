import { registerModule } from '@argon/app/helpers'
import store, { NAMESPACE } from './store'
import routes from './router'

const navItems = {
  organizations: [
    {
      name: 'dealHeader',
      weight: 20,
      component: () => import('./components/DealHeader')
    },
    {
      name: 'homeDeals',
      weight: 21,
      component: () => import('./components/HomeDealsNavItem')
    },
    {
      name: 'groupDeals',
      weight: 22,
      component: () => import('./components/GroupDealsNavItem')
    },
    {
      name: 'individualDeals',
      weight: 23,
      component: () => import('./components/IndividualDealsNavItem')
    }
  ]
}

const VuePlugin = {
  install: (Vue, options) => {
    registerModule(options.router, options.store, options.i18n, {
      navItems: navItems,
      namespace: NAMESPACE,
      routes: routes,
      namespaceStore: store
    })
  }
}

export default VuePlugin
