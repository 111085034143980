import { axios } from '@argon/app/axios'

const NAMESPACE = 'users'

const state = {
  users: [],
  tableItemsCount: null,
  tablePagination: {}
}

const mutations = {
  SET_USERS: (state, payload) => {
    state.users = payload
  },
  SET_TABLE_PAGINATION: (state, payload) => {
    state.tablePagination = payload
  },
  SET_TABLE_ITEMS: (state, payload) => {
    state.tableItems = payload
  },
  SET_TABLE_ITEMS_COUNT: (state, payload) => {
    state.tableItemsCount = payload
  }
}

const actions = {
  listUsers: ({ commit, rootState }, payload) => {
    const orgId = rootState.organizations.defaultOrgId
    const { filters, pager } = payload
    let pagerParams = pager || state.tablePagination
    let filterParams = filters ? { filters } : {}
    let params = { include: 'user,account', ...filterParams, ...pagerParams }
    return new Promise((resolve, reject) => {
      axios({
        url: `/organizations/${orgId}/users`,
        method: 'GET',
        params
      })
        .then(({ data }) => {
          commit('SET_USERS', data.items)
          commit('SET_TABLE_ITEMS_COUNT', data.count)
          commit('SET_TABLE_PAGINATION', pagerParams)
          resolve(data.items)
        })
        .catch(reject)
    })
  },

  createUser: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      axios({
        url: '/users',
        data: { ...payload, email_verified: true },
        method: 'POST'
      })
        .then(({ data }) => {
          let updated = [...state.users, data]
          commit('SET_USERS', updated)
          resolve(data)
        })
        .catch(reject)
    })
  },

  getUser: ({}, userId) => {
    return new Promise((resolve, reject) => {
      axios({
        url: `/users/${userId}`,
        method: 'GET'
      })
        .then(({ data }) => {
          resolve(data)
        })
        .catch(reject)
    })
  },

  patchUser: ({ state, commit }, payload) => {
    const { data, userId } = payload
    return new Promise((resolve, reject) => {
      axios({
        url: `/users/${userId}`,
        data,
        method: 'PATCH'
      })
        .then(({ data }) => {
          let updated = state.users.map((x) => (x.id === userId ? data : x))
          commit('SET_USERS', updated)
          resolve(data)
        })
        .catch(reject)
    })
  },
  getAccount: ({}, userId) => {
    return new Promise((resolve, reject) => {
      axios({
        url: `/accounts/${userId}`,
        method: 'GET'
      })
        .then(({ data }) => {
          resolve(data)
        })
        .catch(reject)
    })
  },
  patchAccount: ({}, payload) => {
    const { data, userId } = payload
    return new Promise((resolve, reject) => {
      axios({
        url: `/accounts/${userId}`,
        data,
        method: 'PATCH'
      })
        .then(({ data }) => {
          resolve(data)
        })
        .catch(reject)
    })
  },
  deleteUser: ({ dispatch }, payload) => {
    const { userId, pager, filters } = payload
    return new Promise((resolve, reject) => {
      axios({
        url: `/users/${userId}`,
        method: 'DELETE'
      })
        .then(() => {
          return dispatch('listUsers', { pager, filters })
        })
        .catch(reject)
    })
  },
  clearUsers: ({ commit }) => {
    return new Promise((resolve, reject) => {
      commit('SET_USERS', [])
      resolve(true)
    })
  },
  clearCurrentUser: ({ commit }) => {
    return new Promise((resolve, reject) => {
      commit('SET_CURRENT_USER', {})
      resolve(true)
    })
  }
}

const getters = {
  users: (state) => state.users,
  currentUser: (state) => state.currentUser
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}

export { NAMESPACE }
