<template>
  <VModalWidget :submit="doSubmit" :dismiss="dismiss" :submitting="submitting" large :submit-title="$t('labels.save')">
    <template v-slot:title>{{ $t('labels.responses') }}</template>

    <template v-slot:content>
      <FormErrors :show="showResourceErrors" :errors="resourceErrors" />
      <component :is="dynamicComponent" :responses="form" :on-changed="onChanged" />
    </template>
  </VModalWidget>
</template>

<script>
import pick from 'lodash/pick'
import { VModalWidget, BaseForm, FormErrors } from '@argon/app/components'
import { IAGResponses } from '../iag'
import { CLAResponses } from '../cla'

export default {
  name: 'ResponsesModal',
  extends: BaseForm,
  components: {
    VModalWidget,
    FormErrors,
    IAGResponses,
    CLAResponses
  },
  props: {
    policy: { type: Object, required: true },
    submit: { type: Function, required: true },
    dismiss: { type: Function, required: true }
  },
  validations: {
    form: {}
  },
  data: () => ({
    form: {},
    submitting: false
  }),
  computed: {
    dynamicComponent() {
      switch (this.policy.insurer) {
        case 'IAG':
          return 'IAGResponses'
          break

        default:
          return 'CLAResponses'
          break
      }
    }
  },
  methods: {
    changeClientResponse(field, value) {
      this.form[field] = value
    },
    onChanged(v) {
      return (value) => this.changeClientResponse(v, value)
    },
    doSubmit() {
      this.submitting = true
      this.submit(this.form)
        .then(() => {
          this.dismiss()
        })
        .finally(() => {
          this.submitting = false
        })
    }
  },
  created() {
    this.form = { ...this.policy.deal_client.meta.info }
  }
}
</script>
