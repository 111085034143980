import { axios } from '@argon/app/axios'
import { toFilterString, stampItem, stampItems } from '@argon/app/helpers'
import { reject as _reject } from 'lodash/collection'

const NAMESPACE = 'clients'

const state = {
  clients: [],
  filterObject: null,
  client: null,
  deals: [],
  notes: [],
  tableItemsCount: null,
  tablePagination: {}
}

const mutations = {
  SET_CLIENTS: (state, payload) => {
    state.clients = payload
  },
  SET_FILTERS: (state, payload) => {
    state.filterObject = payload
  },
  SET_CLIENT: (state, payload) => {
    state.client = payload
  },
  SET_DEALS: (state, payload) => {
    state.deals = payload
  },
  SET_DEALS_COUNT: (state, payload) => {
    state.dealsCount = payload
  },
  SET_NOTES: (state, payload) => {
    state.notes = payload
  },
  SET_NOTES_COUNT: (state, payload) => {
    state.notesCount = payload
  },
  SET_TABLE_PAGINATION: (state, payload) => {
    state.tablePagination = payload
  },
  SET_TABLE_ITEMS_COUNT: (state, payload) => {
    state.tableItemsCount = payload
  }
}
const actions = {
  setFilters: ({ commit }, filterObject) => {
    commit('SET_FILTERS', stampItem(filterObject))
  },
  listClients: ({ commit, rootState }, { pager }) => {
    const filters = toFilterString(rootState.app.appliedFilters[NAMESPACE])
    let pagerParams = pager || state.tablePagination
    let filterParams = filters ? { filters } : {}
    let params = { ...filterParams, ...pagerParams }
    return new Promise((resolve, reject) => {
      axios({
        url: '/clients',
        method: 'GET',
        params
      })
        .then(({ data }) => {
          commit('SET_CLIENTS', stampItems(data.items))
          commit('SET_TABLE_ITEMS_COUNT', data.count)
          commit('SET_TABLE_PAGINATION', pagerParams)
          resolve(data.items)
        })
        .catch(reject)
    })
  },
  clearClients: ({ commit }) => {
    return new Promise((resolve, reject) => {
      commit('SET_CLIENTS', [])
      commit('SET_CLIENT', null)
      resolve(true)
    })
  },
  getClient: ({ commit }, clientId) => {
    return new Promise((resolve, reject) => {
      axios({
        url: `/clients/${clientId}`,
        method: 'get'
      })
        .then(({ data }) => {
          commit('SET_CLIENT', data)
          resolve(data)
        })
        .catch(reject)
    })
  },
  clearClient: ({ commit }) => {
    return new Promise((resolve, reject) => {
      commit('SET_CLIENT', null)
      resolve(true)
    })
  },
  getClientDeals: ({ commit }, clientId) => {
    return new Promise((resolve, reject) => {
      axios({
        url: `/clients/${clientId}/deals`,
        method: 'get',
        params: { include: 'board' }
      })
        .then(({ data }) => {
          commit('SET_DEALS', data.items)
          commit('SET_DEALS_COUNT', data.count)
          resolve(data.items)
        })
        .catch(reject)
    })
  },
  clearClientDeals: ({ commit }) => {
    return new Promise((resolve, reject) => {
      commit('SET_DEALS', [])
      resolve(true)
    })
  },
  getClientNotes: ({ commit }, { filters, pager, clientId }) => {
    let pagerParams = pager || state.tablePagination
    let filterParams = filters ? { filters } : {}
    let params = { include: 'owner,entity', ...filterParams, ...pagerParams }
    return new Promise((resolve, reject) => {
      axios({
        url: `/clients/${clientId}/notes`,
        method: 'get',
        params
      })
        .then(({ data }) => {
          commit('SET_NOTES', data.items)
          commit('SET_NOTES_COUNT', data.count)
          resolve(data.items)
        })
        .catch(reject)
    })
  },
  clearClientNotes: ({ commit }) => {
    return new Promise((resolve, reject) => {
      commit('SET_NOTES', [])
      resolve(true)
    })
  },
  patchClient: ({ commit }, payload) => {
    const { data, clientId } = payload
    return new Promise((resolve, reject) => {
      axios({
        url: `/clients/${clientId}`,
        data,
        method: 'PATCH'
      })
        .then(({ data }) => {
          commit('SET_CLIENT', data)
          resolve(data)
        })
        .catch(reject)
    })
  },

  createClient: ({ commit, state }, data) => {
    let url = '/clients'
    return new Promise((resolve, reject) => {
      axios({
        url,
        data,
        method: 'POST'
      })
        .then(({ data }) => {
          commit('SET_CLIENTS', [...state.clients, data])
          resolve(data)
        })
        .catch(reject)
    })
  },
  deleteClient: ({ commit, state }, client) => {
    let url = `/clients/${client.id}`
    return new Promise((resolve, reject) => {
      axios({
        url,
        method: 'DELETE'
      })
        .then(() => {
          commit(
            'SET_CLIENTS',
            state.clients.filter((item) => item.id !== client.id)
          )
          resolve(true)
        })
        .catch(reject)
    })
  }
}
const getters = {
  clients: (state) => state.clients,
  client: (state) => state.client,
  deals: (state) => state.deals,
  notes: (state) => state.notes,
  getClientById: (state) => (id) => state.clients.find((item) => item.id === parseInt(id))
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}

export { NAMESPACE }
