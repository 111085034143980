<template>
  <div>
    <v-row>
      <v-col cols="12">
        <v-toolbar flat dense color="transparent">
          <v-toolbar-title class="subheading">{{ $t('emails') }}</v-toolbar-title>
          <v-spacer />
          <v-btn icon small>
            <v-icon small @click="addOne">{{ $vuetify.icons.values.add }}</v-icon>
          </v-btn>
        </v-toolbar>
      </v-col>
      <v-col cols="12" v-if="hasOne">
        <EmailLine
          v-for="(email, idx) in localEmails"
          :key="`emailline-${idx}`"
          :idx="idx"
          :email="email"
          :remove-one="removeOne"
          :update="update"
          :set-parent-error="setError"
          :clear-parent-error="clearError"
          :required="required"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import EmailLine from './EmailLine'
import ErrorMixin from './WidgetErrorMixin.js'

const WIDGET = 'email'
const DEFAULT = {
  address: null,
  type: 'work'
}

export default {
  name: 'EmailsWidget',
  mixins: [ErrorMixin],
  components: { EmailLine },
  props: {
    emails: { type: Array, default: () => [] },
    updateEmails: { type: Function, required: true },
    required: { type: Boolean, default: false }
  },
  data: () => ({
    localEmails: [{ ...DEFAULT }]
  }),
  computed: {
    hasOne() {
      return this.localEmails.length > 0
    }
  },
  watch: {
    hasErrors: function (v) {
      if (v) {
        this.setParentError(WIDGET)
      } else {
        this.clearParentError(WIDGET)
      }
    }
  },
  methods: {
    sendUpdated() {
      let cleaned = this.localEmails.filter((item) => item.address && item.type)
      this.updateEmails(cleaned)
    },
    addOne() {
      this.localEmails = [...this.localEmails, { ...DEFAULT }]
    },
    removeOne(idx) {
      if (this.localEmails.length > 1) {
        this.localEmails = this.localEmails.filter((item, i) => i !== idx)
        this.clearError(idx)
      } else {
        this.resetErrors()
        this.localEmails = this.required ? [{ ...DEFAULT }] : []
      }
      this.sendUpdated()
    },
    update(idx, key, value) {
      this.localEmails[idx][key] = value
      this.sendUpdated()
    }
  },
  created() {
    if (this.emails.length > 0) {
      this.localEmails = [...this.emails]
    } else {
      this.localEmails = this.required ? [{ ...DEFAULT }] : []
    }
  }
}
</script>

<style lang="scss" scoped>
.v-toolbar {
  >>> .v-toolbar__content {
    padding: 0;
    margin-bottom: 5px;

    .v-btn--icon {
      margin-right: 6px;
      margin-left: 0;
    }
  }
}
</style>

<i18n>
{
  "en": {
    "addEmail": "Add Email",
    "emails": "Emails"
  },
  "fr": {
    "addEmail": "Ajouter un courriel",
    "emails": "Courriels"
  }
}
</i18n>
