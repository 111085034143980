<template>
  <v-row align="baseline">
    <v-col cols="8">
      <PhoneField
        default-country-code="CA"
        :icon="$vuetify.icons.values.phone"
        prepend-icon
        v-model="number"
 
        :errors="numberErrors"
        :required="required"
        fieldClass="mb-0 pt-0"
      />
    </v-col>
    <v-col cols="3">
      <v-select
        v-model="phoneType"
        :placeholder="$t('labels.phoneType')"
        :items="types"
        item-text="display"
        item-value="value"
        single-line
        class="mb-0 pt-0"
      />
    </v-col>
    <v-col cols="1">
      <v-btn small icon @click="removeOne(idx)">
        <v-icon small>{{ $vuetify.icons.values.close }}</v-icon>
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
import { isIntlPhoneNumber } from '@argon/app/helpers'
import { PhoneField } from '@argon/app/components'

export default {
  name: 'PhoneLine',
  components: { PhoneField },
  validations: {
    number: { isIntlPhoneNumber }
  },
  data: function () {
    return {
      types: [
        { value: 'mobile', display: this.$t('labels.mobile') },
        { value: 'home', display: this.$t('labels.landline') },
        { value: 'work', display: this.$t('labels.work') },
        { value: 'other', display: this.$t('labels.other') }
      ]
    }
  },
  props: {
    idx: Number,
    required: { type: Boolean, default: false },
    phone: Object,
    removeOne: Function,
    update: Function,
    setParentError: Function,
    clearParentError: Function
  },
  computed: {
    number: {
      get() {
        return this.phone.number
      },
      set(value) {
        this.update(this.idx, 'number', value)
      }
    },
    phoneType: {
      get() {
        return this.phone.type
      },
      set(value) {
        this.update(this.idx, 'type', value)
      }
    },
    numberErrors() {
      const errors = []
      if (!this.$v.number.$dirty) return errors
      !this.$v.number.isIntlPhoneNumber && errors.push(this.$t('labels.phoneWrongFormat'))
      return errors
    }
  },
  watch: {
    '$v.number.$invalid': function _watch$vNumber$invalid(v) {
      if (v) {
        this.setParentError(this.idx)
      } else {
        this.clearParentError(this.idx)
      }
    }
  }
}
</script>
