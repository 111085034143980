import { axios } from '@argon/app/axios'
import { reduce } from 'lodash/collection'

const NAMESPACE = 'blockers'

const state = {
  blockers: [],
  presets: [],
  types: [],
  blocker: null
}

const mutations = {
  SET_BLOCKERS: (state, payload) => {
    state.blockers = payload
  },
  SET_PRESETS: (state, payload) => {
    state.presets = payload
  },
  SET_TYPES: (state, payload) => {
    state.types = payload
  },
  SET_BLOCKER: (state, payload) => {
    state.blocker = payload
  }
}
const actions = {
  listBlockerPresets: ({ commit }) => {
    return new Promise((resolve, reject) => {
      axios({
        url: '/blockers/presets',
        method: 'GET'
      })
        .then(({ data }) => {
          commit('SET_PRESETS', data.presets)
          resolve(data.presets)
        })
        .catch(reject)
    })
  },
  listTypes: ({ commit }) => {
    return new Promise((resolve, reject) => {
      axios({
        url: '/blockers/types',
        method: 'GET'
      })
        .then(({ data }) => {
          commit('SET_TYPES', data.types)
          resolve(data.types)
        })
        .catch(reject)
    })
  },
  listBlockers: ({ commit }, dealId) => {
    return new Promise((resolve, reject) => {
      axios({
        url: `/deals/${dealId}/blockers`,
        method: 'GET'
      })
        .then(({ data }) => {
          commit('SET_BLOCKERS', data.items)
          resolve(data.items)
        })
        .catch(reject)
    })
  },
  getBlocker: ({ commit }, dealId) => {
    return new Promise((resolve, reject) => {
      axios({
        url: `/deals/${dealId}/blockers/${dealId}`,
        method: 'GET'
      })
        .then(({ data }) => {
          commit('SET_BLOCKER', data)
          resolve(data)
        })
        .catch(reject)
    })
  },
  createBlocker: ({ dispatch }, payload) => {
    const { dealId, data } = payload
    return new Promise((resolve, reject) => {
      axios({
        url: `/deals/${dealId}/blockers`,
        data,
        method: 'POST'
      })
        .then(({ data }) => {
          dispatch('listBlockers', dealId)
          resolve(data)
        })
        .catch(reject)
    })
  },
  deleteBlocker: ({ dispatch }, dealId) => {
    return new Promise((resolve, reject) => {
      axios({
        url: `/deals/${dealId}/blockers/${dealId}`,
        method: 'DELETE'
      })
        .then(({ data }) => {
          dispatch('listBlockers', dealId)
          resolve(true)
        })
        .catch(reject)
    })
  }
}
const getters = {
  blockers: (state) => state.blockers,
  types: (state) => state.types
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}

export { NAMESPACE }
