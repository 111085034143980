<template>
  <v-row>
    <v-col cols="6">
      <v-list dense subheader>
        <v-list-item>
          <v-list-item-subtitle>{{ $t('labels.verifiedDealInfo') }}</v-list-item-subtitle>
          <v-list-item-action class="tri-state">
            <VTriState
              :choice="responses.has_verified_info"
              :on-changed="onChanged('has_verified_info')"
              :read-only="readOnly"
            />
          </v-list-item-action>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item>
          <v-list-item-subtitle>{{ $t('labels.acceptedInsuranceConditions') }}</v-list-item-subtitle>
          <v-list-item-action class="tri-state">
            <VTriState
              :choice="responses.has_accepted_conditions"
              :on-changed="onChanged('has_accepted_conditions')"
              :read-only="readOnly"
            />
          </v-list-item-action>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item>
          <v-list-item-subtitle>{{ $t('labels.confirmedAuthorization') }}</v-list-item-subtitle>
          <v-list-item-action class="tri-state">
            <VTriState
              :choice="responses.has_confirmed_authorization"
              :on-changed="onChanged('has_confirmed_authorization')"
              :read-only="readOnly"
            />
          </v-list-item-action>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item>
          <v-list-item-subtitle>{{ $t('labels.acceptedBankingAgreement') }}</v-list-item-subtitle>
          <v-list-item-action class="tri-state">
            <VTriState
              :choice="responses.has_accepted_banking_info"
              :on-changed="onChanged('has_accepted_banking_info')"
              :read-only="readOnly"
            />
          </v-list-item-action>
        </v-list-item>
      </v-list>
    </v-col>
    <v-col cols="6">
      <v-list dense subheader>
        <v-list-item>
          <v-list-item-subtitle>{{ $t('labels.acceptedPADAgreement') }}</v-list-item-subtitle>
          <v-list-item-action class="tri-state">
            <VTriState
              :choice="responses.has_accepted_pad"
              :on-changed="onChanged('has_accepted_pad')"
              :read-only="readOnly"
            />
          </v-list-item-action>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item>
          <v-list-item-subtitle>{{ $t('labels.acceptedInsurance') }}</v-list-item-subtitle>
          <v-list-item-action class="tri-state">
            <VTriState
              :choice="responses.has_accepted_insurance"
              :on-changed="onChanged('has_accepted_insurance')"
              :read-only="readOnly"
            />
          </v-list-item-action>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item>
          <v-list-item-subtitle>{{ $t('labels.medicalCondition') }}</v-list-item-subtitle>
          <v-list-item-action class="tri-state">
            <VTriState
              :choice="responses.has_medical_condition"
              :on-changed="onChanged('has_medical_condition')"
              :read-only="readOnly"
            />
          </v-list-item-action>
        </v-list-item>
      </v-list>
    </v-col>
  </v-row>
</template>

<script>
import VTriState from '../components/VTriState'
import { ResponseMixin } from '../mixins'

export default {
  name: 'CLAResponses',
  components: { VTriState },
  mixins: [ResponseMixin]
}
</script>

<style lang="css">
.tri-state.v-list__tile__action {
  min-width: 90px;
  text-align: right;
}
</style>
