import { mapActions, mapState } from 'vuex'
import { NAMESPACE } from '../store'
export default {
  props: {
    provided: Array
  },
  methods: {
    ...mapActions(NAMESPACE, ['listRefusals'])
  },
  computed: {
    ...mapState(NAMESPACE, ['refusals']),
    localRefusals() {
      return this.provided || this.refusals
    }
  },
  created() {
    let getRefusals = !this.provided && this.refusals.length === 0
    if (getRefusals) {
      this.listRefusals()
    }
  }
}
