import merge from 'deepmerge'
import colors from 'vuetify/lib/util/colors'

import { preset as argonPreset } from '@argon/vue-cli-plugin-vuetify-preset/preset'

import './styles/overrides.scss'

export const preset = merge(argonPreset, {
  theme: {
    dark: false,
    default: 'light',
    disable: false,
    options: {
      cspNonce: '4E73971F',
      customProperties: true,
      minifyTheme: undefined,
      themeCache: undefined,
      variations: true
    },
    themes: {
      light: {
        primary: '#438cc8',
        secondary: colors.blue.lighten1,
        accent: colors.grey.base,
        error: colors.red.lighten2,
        info: colors.lightBlue.lighten2,
        success: colors.lightGreen.lighten2,
        warning: colors.amber.lighten2,
        grey: colors.grey.base
      }
    }
  },
  icons: {
    values: {
      clients: 'fal fa-address-book',
      firms: 'fal fa-industry',
      leads: 'fal fa-radar',
      deals: 'fal fa-bullseye',
      private: 'fal fa-eye-slash',
      readOnly: 'fal fa-user-lock',
      public: 'fal fa-eye',
      transactions: 'fal fa-exchange-alt',
      send: 'fal fa-paper-plane'
    }
  }
})
