<template>
  <v-row>
    <v-col cols="12">
      <v-responses :policy="policy" v-if="$policy.isAllowed('atLeastAdvisor')" :read-only="readOnly" />
    </v-col>
    <v-col cols="12" md="6">
      <v-row>
        <v-col cols="12">
          <v-products :policy="policy" :read-only="readOnly" />
        </v-col>
        <v-col cols="12">
          <v-beneficiaries :policy="policy" v-if="$policy.isAllowed('atLeastAdvisor')" :read-only="readOnly" />
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12" md="6">
      <v-row>
        <v-col cols="12" v-if="$policy.isAllowed('atLeastAdvisor')">
          <v-decision :policy="policy" :read-only="readOnly" />
        </v-col>
        <v-col cols="12">
          <component :is="dynamicComponent" :policy="policy" />
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { VResponses } from '../responses'
import { VDecision } from '../decision'
import { VProducts } from '../products'
import { VBeneficiaries } from '../beneficiaries'
import { IAGPolicyInfo } from '../iag'
import { CLAPolicyInfo } from '../cla'
import policyPerimeter from '../perimeters'

export default {
  name: 'ApplicantInsurance',
  perimeters: [policyPerimeter],
  components: {
    VResponses,
    VDecision,
    VProducts,
    VBeneficiaries,
    IAGPolicyInfo,
    CLAPolicyInfo
  },
  props: {
    policy: { type: Object, required: true },
    readOnly: { type: Boolean, default: false }
  },
  computed: {
    dynamicComponent() {
      switch (this.policy.insurer) {
        case 'IAG':
          return 'IAGPolicyInfo'
          break
        default:
          return 'CLAPolicyInfo'
          break
      }
    }
  }
}
</script>
