import * as Sentry from '@sentry/browser'
import { Vue as VueIntegration } from '@sentry/integrations'
import { isNil } from 'lodash/lang'

const validEnvs = ['production', 'staging']
const initSentry = (Vue) => {
  let dsn = process.env.VUE_APP_SENTRY_DSN
  // XXX: Attempting to force-enable sentry for the frontend for now.
  const shouldInitSentry = !isNil(dsn) && dsn !== 'false' && validEnvs.includes(process.env.NODE_ENV)
  // const shouldInitSentry = !isNil(dsn) && dsn !== 'false'
  if (shouldInitSentry) {
    Sentry.init({
      dsn,
      integrations: [new VueIntegration({ Vue, attachProps: true, logErrors: true })],
      beforeSend(event, hint) {
        // console.log('event', event)
        // console.log('hint', hint)
        // XXX: decide if error goes through to sentry here.
        return event
      }
    })
  }
}

export default initSentry
