import { registerModule } from '@argon/app/helpers'
import routes from './router'
import store, { NAMESPACE } from './store'

const navItems = {
  organizations: [
    {
      name: 'transactionHeader',
      weight: 30,
      component: () => import('./components/TransactionHeader')
    },
    {
      name: 'transactions',
      weight: 31,
      component: () => import('./components/TransitionsNavItem')
    }
  ]
}

const VuePlugin = {
  install: (Vue, options) => {
    registerModule(options.router, options.store, options.i18n, {
      namespace: NAMESPACE,
      routes: routes,
      namespaceStore: store,
      navItems
    })
  }
}

export default VuePlugin
