import { registerModule } from '@argon/app/helpers'
import store, { NAMESPACE } from '@argon/evo/boards/store'

const VuePlugin = {
  install: (Vue, options) => {
    registerModule(options.router, options.store, options.i18n, {
      namespace: NAMESPACE,
      //   routes: routes,
      namespaceStore: store
    })
  }
}

export default VuePlugin
