var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('VWidget',{attrs:{"content-class":"pa-0","enable-footer-actions":""},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(_vm._s(_vm.$t('labels.products')))]},proxy:true},(_vm.canEdit)?{key:"header-actions",fn:function(){return [_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":_vm.create}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(_vm._s(_vm.$vuetify.icons.values.plus))])],1)]},proxy:true}:null,{key:"content",fn:function(){return [_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.products,"show-expand":"","single-expand":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.display",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$t(("labels." + (item.type))))+" ")]}},{key:"item.fee",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.toCurrency(item.monthly_fee))+" ")]}},(_vm.canEdit)?{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(item.category !== 'discount')?_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":function($event){$event.stopPropagation();return _vm.edit(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(_vm._s(_vm.$vuetify.icons.values.edit))])],1):_vm._e(),_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":function($event){$event.stopPropagation();return _vm.doDeleteProduct(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(_vm._s(_vm.$vuetify.icons.values.trash))])],1)]}}:null,{key:"expanded-item",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"pa-0",attrs:{"colspan":_vm.headers.length}},[(item.meta.dates)?_c('v-product-dates',{attrs:{"utc":"","product":item}}):_vm._e()],1)]}}],null,true)})]},proxy:true}],null,true)},[_c('template',{slot:"footer-actions"},[_c('div',{staticClass:"text-body-2"},[_c('span',{staticClass:"lighten-3 mr-1"},[_vm._v(_vm._s(_vm.$t('labels.monthlyFee'))+":")]),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.toCurrency(_vm.policy.meta.premiums.monthly_fee)))]),_c('span',{staticClass:"ml-2 mr-2"},[_vm._v("•")]),_c('span',{staticClass:"lighten-3 mr-1"},[_vm._v(_vm._s(_vm.$t('labels.totalFee'))+":")]),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.toCurrency(_vm.policy.meta.premiums.total_fee)))])])])],2)}
var staticRenderFns = []

export { render, staticRenderFns }