<template>
  <VModalWidget
    :dismiss="dismiss"
    :submit="doSubmit"
    medium
    no-esc
    :submitting="submitting"
    :submit-title="buttonTitle"
  >
    <template v-slot:title>{{ $t('labels.beneficiary') }}</template>

    <template v-slot:content>
      <v-row>
        <v-col cols="12">
          <v-text-field
            v-model.trim="form.firstname"
            :label="$t('labels.firstName')"
            :error-messages="firstNameErrors"
            @input="$v.form.firstname.$touch()"
            @blur="$v.form.firstname.$touch()"
            required
          />
        </v-col>
        <v-col cols="12">
          <v-text-field
            v-model.trim="form.lastname"
            :label="$t('labels.lastName')"
            :error-messages="lastNameErrors"
            @input="$v.form.lastname.$touch()"
            @blur="$v.form.lastname.$touch()"
            required
          />
        </v-col>
        <v-col cols="12">
          <v-text-field
            v-model.trim="form.relation"
            :label="$t('labels.relation')"
            :error-messages="relationErrors"
            @input="$v.form.relation.$touch()"
            @blur="$v.form.relation.$touch()"
            required
          />
        </v-col>
        <v-col cols="12">
          <DateField date utc v-model="form.birthdate" :label="$t('labels.birthDate')" :errors="dateErrors" />
        </v-col>
        <v-col cols="12">
          <v-text-field
            v-model.trim="form.percentage"
            :placeholder="$t('labels.percentage')"
            :error-messages="percentageErrors"
            @input="$v.form.percentage.$touch()"
            @blur="$v.form.percentage.$touch()"
            required
          />
        </v-col>
      </v-row>
    </template>
  </VModalWidget>
</template>

<script>
import { required, decimal } from 'vuelidate/lib/validators'
import { VModalWidget, BaseForm, DateField } from '@argon/app/components'
import { Modal } from '@argon/app/mixins'
import { mapActions } from 'vuex'
import { NAMESPACE } from '../store'

export default {
  name: 'BeneficiaryModal',
  extends: BaseForm,
  mixins: [Modal],
  components: { VModalWidget, DateField },
  props: {
    entityId: { type: [String, Number], required: true },
    policyId: { type: [String, Number], required: true },
    beneficiaryId: { type: [String, Number], required: true }
  },
  validations: {
    form: {
      firstname: { required },
      lastname: { required },
      relation: { required },
      birthdate: { required },
      percentage: { required, decimal }
    }
  },
  data: () => ({
    form: {
      firstname: '',
      lastname: '',
      relation: '',
      birthdate: '',
      percentage: null
    },
    menu: false,
    submitting: false
  }),
  computed: {
    firstNameErrors() {
      const errors = []
      if (!this.$v.form.firstname.$dirty) return errors
      !this.$v.form.firstname.required && errors.push(this.$t('labels.required'))
      this.getServerErrors('firstname', errors)
      return errors
    },
    lastNameErrors() {
      const errors = []
      if (!this.$v.form.lastname.$dirty) return errors
      !this.$v.form.lastname.required && errors.push(this.$t('labels.required'))
      this.getServerErrors('lastname', errors)
      return errors
    },
    relationErrors() {
      const errors = []
      if (!this.$v.form.relation.$dirty) return errors
      !this.$v.form.relation.required && errors.push(this.$t('labels.required'))
      this.getServerErrors('relation', errors)
      return errors
    },
    percentageErrors() {
      const errors = []
      if (!this.$v.form.percentage.$dirty) return errors
      !this.$v.form.percentage.required && errors.push(this.$t('labels.required'))
      !this.$v.form.percentage.decimal && errors.push(this.$t('labels.invalid'))
      this.getServerErrors('percentage', errors)
      return errors
    },
    dateErrors() {
      const errors = []
      if (!this.$v.form.birthdate.$dirty) return errors
      !this.$v.form.birthdate.required && errors.push(this.$t('labels.required'))
      this.getServerErrors('birthdate', errors)
      return errors
    },
    buttonTitle() {
      return this.beneficiaryId === 'new' ? this.$t('labels.create') : this.$t('labels.save')
    }
  },
  methods: {
    ...mapActions(NAMESPACE, ['createBeneficiary', 'patchBeneficiary']),
    doSubmit() {
      this.resetServerErrors()
      if (this.$v.form.$invalid) {
        return this.$v.form.$touch()
      }
      this.submitting = true
      const callback = this.beneficiaryId === 'new' ? this.createBeneficiary : this.patchBeneficiary
      let payload = {
        dealId: this.entityId,
        policyId: this.policyId,
        data: this.form
      }
      callback(payload)
        .then(() => {
          this.dismiss()
        })
        .catch(this.setServerErrors)
        .finally(() => {
          this.submitting = false
        })
    }
  }
}
</script>
