import DealLogbookPlugin from './deals'
import ProductLogbookPlugin from './products'
import TasksLogbookPlugin from './tasks'
import DocumentsLogbookPlugin from './documents'
import NotesLogbookPlugin from './notes'
import ComplianceLogbookPlugin from './compliance'

const VuePlugin = {
  install: (Vue, options) => {
    const { router, store, i18n } = options

    Vue.use(DealLogbookPlugin, { router, store, i18n })
    Vue.use(ProductLogbookPlugin, { router, store, i18n })
    Vue.use(TasksLogbookPlugin, { router, store, i18n })
    Vue.use(DocumentsLogbookPlugin, { router, store, i18n })
    Vue.use(NotesLogbookPlugin, { router, store, i18n })
    Vue.use(ComplianceLogbookPlugin, { router, store, i18n })
  }
}

export default VuePlugin
