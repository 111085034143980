<template>
  <v-overlay :value="visible">
    <v-progress-circular indeterminate size="64"></v-progress-circular>
  </v-overlay>
</template>

<script>
export default {
  name: 'ModalLoading',
  props: {
    visible: { type: Boolean, default: false }
  }
}
</script>
