<template>
  <v-card outlined>
    <v-list>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-subtitle>{{ $t('insurerStatus') }}</v-list-item-subtitle>
          <v-list-item-title>{{ $t(`labels.${policy.insurer_status}`) }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider />
      <v-list-item>
        <v-list-item-title>{{ $t('insurerDecision') }}</v-list-item-title>
        <v-list-item-action v-if="!readOnly">
          <v-switch v-model="decision" color="success" />
        </v-list-item-action>
      </v-list-item>
    </v-list>
  </v-card>
</template>

<script>
import { mapActions } from 'vuex'
import { NAMESPACE } from '../store'

export default {
  name: 'VDecision',
  props: {
    policy: { type: Object, required: true },
    readOnly: { type: Boolean, default: false }
  },
  computed: {
    accepted() {
      return this.policy.deal_client.insurer_has_accepted
    },
    dealId() {
      return this.policy.deal_id
    },
    clientId() {
      return this.policy.client_id
    },
    decision: {
      get() {
        return this.accepted
      },
      set(v) {
        this.setDecision(v)
      }
    }
  },
  methods: {
    ...mapActions(NAMESPACE, ['patchDealClient']),
    setDecision(v) {
      let payload = {
        dealId: this.dealId,
        clientId: this.clientId,
        data: { meta: { insurer_has_accepted: v } }
      }
      this.patchDealClient(payload)
    }
  }
}
</script>

<style>
.v-toolbar .v-form.decision .v-input {
  margin-top: 16px !important;
}
</style>

<i18n>
{
  "en": {
    "insurerStatus": "Insurer Status",
    "insurerDecision": "Insurer Decision"
  },
  "fr": {
    "insurerStatus": "Statut de l'assureur",
    "insurerDecision": "Décision de l'assureur"
  }
}
</i18n>
