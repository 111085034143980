<template>
  <v-autocomplete
    v-model="localValue"
    :items="localPresets"
    item-text="display"
    item-value="id"
    hide-no-data
    :label="$t('labels.blocker')"
    required
    :error-messages="errors"
    :menu-props="{ maxWidth: '316px', closeOnContentClick: true }"
  />
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { CustomFieldMixin } from '@argon/app/mixins'
import { NAMESPACE } from '../store'
export default {
  name: 'BlockerField',
  mixins: [CustomFieldMixin],
  props: {
    provided: Array
  },
  methods: {
    ...mapActions(NAMESPACE, ['listBlockerPresets'])
  },
  computed: {
    ...mapState(NAMESPACE, ['presets']),
    localPresets() {
      return this.provided || this.presets
    }
  },
  created() {
    let getBlockers = !this.provided && this.presets.length === 0
    if (getBlockers) {
      this.listBlockerPresets().catch(() => {})
    }
  }
}
</script>

<style lang="scss" scoped>
>>> .v-list__tile {
  height: 72px !important;
  align-items: top !important;
}

.v-list__tile__sub-title {
  white-space: normal !important;
}
</style>
