import { render, staticRenderFns } from "./StatusField.vue?vue&type=template&id=ea13c2dc&scoped=true&"
import script from "./StatusField.vue?vue&type=script&lang=js&"
export * from "./StatusField.vue?vue&type=script&lang=js&"
import style0 from "./StatusField.vue?vue&type=style&index=0&id=ea13c2dc&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ea13c2dc",
  null
  
)

/* custom blocks */
import block0 from "./StatusField.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VSelect } from 'vuetify/lib/components/VSelect';
installComponents(component, {VListItemContent,VListItemTitle,VSelect})
