export default [
  {
    path: '/me/profile',
    props: { nav: 'main' },
    component: () => import('@argon/app/components/AppLayout'),
    children: [
      {
        path: '',
        name: 'me',
        component: () => import('./views/Me'),
        props: true,
        meta: {
          perimeterAction: 'read'
        },
        children: [
          {
            path: 'properties',
            name: 'editProfile',
            component: () => import('@argon/iam/me/views/ModalProfile'),
            props: true,
            meta: {
              perimeterAction: 'read'
            }
          },
          {
            path: 'contact',
            name: 'editContact',
            component: () => import('./views/ModalContact'),
            props: true,
            meta: {
              perimeterAction: 'read'
            }
          },
          {
            path: 'account',
            name: 'editAccount',
            component: () => import('@argon/iam/me/views/ModalAccount'),
            props: true,
            meta: {
              perimeterAction: 'read'
            }
          },
          {
            path: 'preferences',
            name: 'editUserPreferences',
            component: () => import('./views/ModalUserPreferences'),
            props: true,
            meta: {
              perimeterAction: 'read'
            }
          },
          {
            path: 'password',
            name: 'editUserPassword',
            component: () => import('@argon/iam/me/views/ModalUserPassword'),
            props: true,
            meta: {
              perimeterAction: 'read'
            }
          },
          {
            path: 'invitations/:inviteRefid',
            name: 'invitation',
            component: () => import('@argon/iam/me/views/ModalInvitation'),
            props: true,
            meta: {
              perimeterAction: 'read'
            }
          }
        ]
      }
    ]
  }
]
