import { render, staticRenderFns } from "./CLAPolicySummary.vue?vue&type=template&id=127c168c&scoped=true&"
import script from "./CLAPolicySummary.vue?vue&type=script&lang=js&"
export * from "./CLAPolicySummary.vue?vue&type=script&lang=js&"
import style0 from "./CLAPolicySummary.vue?vue&type=style&index=0&id=127c168c&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "127c168c",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VSubheader } from 'vuetify/lib/components/VSubheader';
installComponents(component, {VDivider,VList,VListItem,VListItemContent,VListItemSubtitle,VListItemTitle,VSubheader})
