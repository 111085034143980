import { registerModule } from '@argon/app/helpers'

import store, { NAMESPACE } from './store'

const VuePlugin = {
  install: (Vue, options) => {
    registerModule(options.router, options.store, options.i18n, {
      namespace: NAMESPACE,
      namespaceStore: store
    })
  }
}

export default VuePlugin
