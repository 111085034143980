<template>
  <v-select
    v-model="localValue"
    :items="localStatuses"
    item-value="value"
    hide-no-data
    :label="$t('status')"
    required
    :error-messages="errors"
    :menu-props="{ maxWidth: '316px', closeOnContentClick: true }"
  >
    <template v-slot:selection="{ item }">
      <v-list-item-content>{{ $t(`labels.${item.name.toLowerCase()}`) }}</v-list-item-content>
    </template>

    <template v-slot:item="{ item }">
      <v-list-item-content>
        <v-list-item-title>{{ $t(`labels.${item.name.toLowerCase()}`) }}</v-list-item-title>
      </v-list-item-content>
    </template>
  </v-select>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { CustomFieldMixin } from '@argon/app/mixins'
import { NAMESPACE } from '../store'

export default {
  name: 'StatusField',
  mixins: [CustomFieldMixin],
  props: {
    provided: Array
  },
  methods: {
    ...mapActions(NAMESPACE, ['listStatuses'])
  },
  computed: {
    ...mapState(NAMESPACE, ['statuses']),
    localStatuses() {
      return this.provided || this.statuses
    }
  },
  created() {
    let getStatuses = !this.provided && this.statuses.length === 0
    if (getStatuses) {
      this.listStatuses().catch(() => {})
    }
  }
}
</script>

<style lang="scss" scoped>
>>> .v-list__tile {
  height: 72px !important;
  align-items: top !important;
}

.v-list__tile__sub-title {
  white-space: normal !important;
}
</style>

<i18n>
{
  "en": {
    "status": "@:labels.status"
  },
  "fr": {
    "status": "@:labels.status"
  }
}
</i18n>
