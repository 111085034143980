<template>
  <div>
    <v-row>
      <v-col cols="12">
        <v-toolbar flat dense color="transparent">
          <v-toolbar-title class="subheading">{{ $t('phones') }}</v-toolbar-title>
          <v-spacer />
          <v-btn icon small>
            <v-icon small @click="addOne">{{ $vuetify.icons.values.add }}</v-icon>
          </v-btn>
        </v-toolbar>
      </v-col>
      <v-col cols="12" v-if="hasOne">
        <PhoneLine
          v-for="(phone, idx) in localPhones"
          :key="`phoneline-${idx}`"
          :idx="idx"
          :phone="phone"
          :remove-one="removeOne"
          :update="update"
          :required="required"
          :set-parent-error="setError"
          :clear-parent-error="clearError"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import PhoneLine from './PhoneLine'
import ErrorMixin from './WidgetErrorMixin.js'

const WIDGET = 'phone'
const DEFAULT = {
  number: null,
  type: 'mobile'
}

export default {
  name: 'PhonesWidget',
  mixins: [ErrorMixin],
  components: { PhoneLine },
  props: {
    phones: { type: Array, default: () => [] },
    updatePhones: { type: Function, required: true },
    required: { type: Boolean, default: false }
  },
  data: () => ({
    localPhones: [{ ...DEFAULT }]
  }),
  computed: {
    hasOne() {
      return this.localPhones.length > 0
    }
  },
  watch: {
    hasErrors: function (v) {
      if (v) {
        this.setParentError(WIDGET)
      } else {
        this.clearParentError(WIDGET)
      }
    }
  },
  methods: {
    sendUpdated() {
      let cleaned = this.localPhones.filter((item) => item.number && item.type)
      this.updatePhones(cleaned)
    },
    addOne() {
      this.localPhones = [...this.localPhones, { ...DEFAULT }]
    },
    removeOne(idx) {
      if (this.localPhones.length > 1) {
        this.localPhones = this.localPhones.filter((item, i) => i !== idx)
        this.clearError(idx)
      } else {
        this.resetErrors()
        this.localPhones = this.required ? [{ ...DEFAULT }] : []
      }
      this.sendUpdated()
    },
    update(idx, key, value) {
      this.localPhones[idx][key] = value
      this.sendUpdated()
    }
  },
  created() {
    if (this.phones.length > 0) {
      this.localPhones = [...this.phones]
    } else {
      this.localPhones = this.required ? [{ ...DEFAULT }] : []
    }
  }
}
</script>

<style lang="scss" scoped>
.v-toolbar {
  >>> .v-toolbar__content {
    padding: 0;
    margin-bottom: 5px;

    .v-btn--icon {
      margin-right: 6px;
      margin-left: 0;
    }
  }
}
</style>

<i18n>
{
  "en": {
    "addPhone": "Add Phone",
    "phones": "Phones"
  },
  "fr": {
    "addPhone": "Ajouter un téléphone",
    "phones": "Téléphones"
  }
}
</i18n>
