export const money = (v, locale) =>
  v ? parseFloat(v).toLocaleString(locale, { style: 'currency', currency: 'CAD' }) : ''

export const getSummaryStyle = (label, category) => ({
  color: 'blue',
  icon: 'add'
})

const correctRe = /\{[a-z_]+\}/gim

export const hasValidPlaceholders = (text) => {
  if (!text) {
    return true
  }
  const openCount = (text.match(/\{/g) || []).length
  const closeCount = (text.match(/\}/g) || []).length
  const matchesCount = (text.match(correctRe) || []).length
  const allOfThem = [openCount, closeCount, matchesCount]
  // if openCount === closeCount === matchesCount, we're good
  return allOfThem.every((n) => n === allOfThem[0])
}
