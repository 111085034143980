import { reject } from 'lodash/collection'

export default {
  props: {
    required: { type: Boolean, default: false },
    setParentError: Function,
    clearParentError: Function
  },
  data: () => ({
    localErrors: []
  }),
  methods: {
    setError(idx) {
      this.localErrors = [...this.localErrors, idx]
    },
    clearError(idx) {
      this.localErrors = reject(this.localErrors, (x) => x === idx)
    },
    resetErrors() {
      this.localErrors = []
    }
  },
  computed: {
    hasErrors() {
      return this.localErrors.length !== 0
    }
  }
}
