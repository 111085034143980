import { axios } from '@argon/app/axios'

const NAMESPACE = 'insurance'
const ACCEPTED = 'accepted'
const UNDECIDED = 'undecided'
const REJECTED = 'rejected'

const state = {
  policies: [],
  contracts: [],
  products: [],
  insurers: []
}

const mutations = {
  SET_PRODUCTS: (state, payload) => {
    state.products = payload
  },
  SET_DEAL_POLICIES: (state, payload) => {
    state.policies = payload
  },
  SET_CONTRACTS: (state, payload) => {
    state.contracts = payload
  },
  SET_INSURERS: (state, payload) => {
    state.insurers = payload
  }
}
const actions = {
  listProducts: ({ commit, dispatch }, dealId) => {
    return new Promise((resolve, reject) => {
      axios({
        url: `/deals/${dealId}/products`,
        method: 'GET'
      })
        .then(({ data }) => {
          commit('SET_PRODUCTS', data.items)
          resolve(data.items)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  listDealPolicies: ({ commit }, dealId) => {
    return new Promise((resolve, reject) => {
      axios({
        url: `/deals/${dealId}/policies`,
        params: { include: 'products,beneficiaries,client,deal_client' },
        method: 'GET'
      })
        .then(({ data }) => {
          commit('SET_DEAL_POLICIES', data.items)
          resolve(data.items)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  clearDealPolicies: ({ commit }) => {
    return new Promise((resolve, reject) => {
      commit('SET_DEAL_POLICIES', [])
      commit('SET_CONTRACTS', [])
      resolve(true)
    })
  },
  getDefaultDates: ({}, payload) => {
    const { dealId, policyId } = payload
    return new Promise((resolve, reject) => {
      axios({
        url: `/deals/${dealId}/policies/${policyId}/dates`,
        method: 'GET'
      })
        .then(({ data }) => {
          resolve(data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  createProduct: ({ dispatch }, payload) => {
    const { dealId, policyId, data } = payload
    return new Promise((resolve, reject) => {
      axios({
        url: `/deals/${dealId}/policies/${policyId}/products`,
        data: { policy_id: policyId, ...data },
        method: 'POST'
      })
        .then(({ data }) => {
          dispatch('listDealPolicies', dealId)
          resolve(data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  patchProduct: ({ dispatch }, payload) => {
    const { dealId, policyId, productId, data } = payload
    return new Promise((resolve, reject) => {
      axios({
        url: `/deals/${dealId}/policies/${policyId}/products/${productId}`,
        data,
        method: 'PATCH'
      })
        .then(({ data }) => {
          dispatch('listDealPolicies', dealId)
          resolve(data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getProduct: ({}, payload) => {
    const { dealId, policyId, productId } = payload
    return new Promise((resolve, reject) => {
      axios({
        url: `/deals/${dealId}/policies/${policyId}/products/${productId}`,
        method: 'GET'
      })
        .then(({ data }) => {
          resolve(data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  deleteProduct: ({ dispatch }, payload) => {
    const { dealId, policyId, productId } = payload
    return new Promise((resolve, reject) => {
      axios({
        url: `/deals/${dealId}/policies/${policyId}/products/${productId}`,
        method: 'DELETE'
      })
        .then(() => {
          dispatch('listDealPolicies', dealId)
          resolve(true)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  createBeneficiary: ({}, payload) => {
    const { dealId, policyId, data } = payload
    return new Promise((resolve, reject) => {
      axios({
        url: `/deals/${dealId}/policies/${policyId}/beneficiaries`,
        data,
        method: 'POST'
      })
        .then(({ data }) => {
          resolve(data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  patchBeneficiary: ({}, payload) => {
    const { dealId, policyId, beneficiaryId, data } = payload
    return new Promise((resolve, reject) => {
      axios({
        url: `/deals/${dealId}/policies/${policyId}/beneficiaries/${beneficiaryId}`,
        data,
        method: 'PATCH'
      })
        .then(({ data }) => {
          resolve(data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  deleteBeneficiary: ({}, payload) => {
    const { dealId, policyId, beneficiaryId } = payload
    return new Promise((resolve, reject) => {
      axios({
        url: `/deals/${dealId}/policies/${policyId}/beneficiaries/${beneficiaryId}`,
        method: 'DELETE'
      })
        .then(() => {
          resolve(true)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  patchDealClient: ({}, payload) => {
    const { dealId, clientId, data } = payload
    return new Promise((resolve, reject) => {
      axios({
        url: `/deals/${dealId}/clients/${clientId}`,
        data,
        method: 'PATCH'
      })
        .then(({ data }) => {
          resolve(data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  listContracts: ({ commit }, dealId) => {
    return new Promise((resolve, reject) => {
      axios({
        url: `/deals/${dealId}/contracts`,
        params: { sort_by: 'created_on', sort_order: 'desc' },
        method: 'GET'
      })
        .then(({ data }) => {
          commit('SET_CONTRACTS', data.items)
          resolve(data.items)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  generateContract: ({ dispatch }, dealId) => {
    return new Promise((resolve, reject) => {
      axios({
        url: `/deals/${dealId}/contracts`,
        data: {},
        method: 'POST'
      })
        .then(({ data }) => {
          dispatch('listContracts', dealId)
          resolve(data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  sendContractFile: ({}, dealId) => {
    return new Promise((resolve, reject) => {
      axios({
        url: `/deals/${dealId}/send-contract`,
        data: {},
        method: 'POST'
      })
        .then(({}) => {
          resolve(true)
        })
        .catch(reject)
    })
  },
  recomputePremiums: ({ dispatch }, dealId) => {
    return new Promise((resolve, reject) => {
      axios({
        url: `/deals/${dealId}/recompute-premiums`,
        data: {},
        method: 'POST'
      })
        .then(({}) => {
          return dispatch('listDealPolicies', dealId)
        })
        .then(({}) => {
          resolve(true)
        })
        .catch(reject)
    })
  },
  listInsurers: ({ commit }, dealId) => {
    return new Promise((resolve, reject) => {
      axios({
        url: `insurers`,
        method: 'GET'
      })
        .then(({ data }) => {
          commit('SET_INSURERS', data.items)
          resolve(data.items)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  clearInsurers: ({ commit }) => {
    commit('SET_INSURERS', [])
  }
}
const getters = {
  policies: (state) => state.policies,
  products: (state) => state.products
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}

export { NAMESPACE, ACCEPTED, UNDECIDED, REJECTED }
