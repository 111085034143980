import { axios, AND_SUB_SEPARATOR, OR_SEPARATOR, AND_SEPARATOR, EQ_OPERATOR } from '@argon/app/axios'
import { toFilterString } from '@argon/app/helpers'

export const NAMESPACE = 'home'
export const OVERDUEBROKERS = 'overdueBrokers'
export const OVERDUETASK = 'overdueTask'
export const PENDINGTASKS = 'pendingTasks'
export const NONCOMPLIANTDEALS = 'nonCompliantDeals'
export const PENDINGCONTACTDEALS = 'pendingContactDeals'
export const PENDINGBROKERDEALS = 'pendingBrokerDeals'
export const SOLDBROKERDEALS = 'soldBrokerDeals'
export const CONTACTEDBROKERDEALS = 'contactedBrokerDeals'
export const COMPLETEDBROKERDEALS = 'completedBrokerDeals'

const state = {
  title: 'Simpl Dashboard',
  summary: {},
  dailies: [],
  weeklies: [],
  pendingTasks: [],
  pendingTasksCount: null,
  overdueTasks: [],
  overdueTasksCount: null,
  overdueBrokers: [],
  overdueBrokersCount: null,
  nonCompliantDeals: [],
  nonCompliantDealsCount: null,
  pendingContactDeals: [],
  pendingContactDealsCount: null,
  pendingBrokerDeals: [],
  pendingBrokerDealsCount: null,
  contactedBrokerDeals: [],
  contactedBrokerDealsCount: null,
  soldBrokerDeals: [],
  soldBrokerDealsCount: null,
  completedBrokerDeals: [],
  completedBrokerDealsCount: null,
  legacyExports: []
}

const mutations = {
  SET_SUMMARY: (state, payload) => {
    state.summary = payload
  },
  SET_DAILY_SUMMARY: (state, payload) => {
    state.dailies = payload
  },
  SET_WEEKLY_SUMMARY: (state, payload) => {
    state.weeklies = payload
  },
  SET_OVERDUE_TASKS: (state, payload) => {
    state.overdueTasks = payload
  },
  SET_OVERDUE_TASKS_COUNT: (state, payload) => {
    state.overdueTasksCount = payload
  },
  SET_PENDING_TASKS: (state, payload) => {
    state.pendingTasks = payload
  },
  SET_PENDING_TASKS_COUNT: (state, payload) => {
    state.pendingTasksCount = payload
  },
  SET_OVERDUE_BROKERS: (state, payload) => {
    state.overdueBrokers = payload
  },
  SET_OVERDUE_BROKERS_COUNT: (state, payload) => {
    state.overdueBrokersCount = payload
  },
  SET_NON_COMPLIANT_DEALS: (state, payload) => {
    state.nonCompliantDeals = payload
  },
  SET_NON_COMPLIANT_DEALS_COUNT: (state, payload) => {
    state.nonCompliantDealsCount = payload
  },
  SET_PENDING_CONTACT_DEALS: (state, payload) => {
    state.pendingContactDeals = payload
  },
  SET_PENDING_CONTACT_DEALS_COUNT: (state, payload) => {
    state.pendingContactDealsCount = payload
  },
  SET_PENDING_BROKER_DEALS: (state, payload) => {
    state.pendingBrokerDeals = payload
  },
  SET_PENDING_BROKER_DEALS_COUNT: (state, payload) => {
    state.pendingBrokerDealsCount = payload
  },
  SET_CONTACTED_BROKER_DEALS: (state, payload) => {
    state.contactedBrokerDeals = payload
  },
  SET_CONTACTED_BROKER_DEALS_COUNT: (state, payload) => {
    state.contactedBrokerDealsCount = payload
  },
  SET_SOLD_BROKER_DEALS: (state, payload) => {
    state.soldBrokerDeals = payload
  },
  SET_SOLD_BROKER_DEALS_COUNT: (state, payload) => {
    state.soldBrokerDealsCount = payload
  },
  SET_COMPLETED_BROKER_DEALS: (state, payload) => {
    state.completedBrokerDeals = payload
  },
  SET_COMPLETED_BROKER_DEALS_COUNT: (state, payload) => {
    state.completedBrokerDealsCount = payload
  },
  SET_LEGACY_EXPORTS: (state, payload) => {
    state.legacyExports = payload
  }
}

const actions = {
  getSummary: ({ commit }) => {
    return new Promise((resolve, reject) => {
      axios({
        url: '/dashboards/summary',
        method: 'GET'
      })
        .then(({ data }) => {
          commit('SET_SUMMARY', data)
          resolve(data)
        })
        .catch(reject)
    })
  },
  getDailySummary: ({ commit }) => {
    return new Promise((resolve, reject) => {
      axios({
        url: '/dashboards/daily',
        method: 'GET'
      })
        .then(({ data }) => {
          commit(
            'SET_DAILY_SUMMARY',
            data.items.filter((x) => x.items.length > 0)
          )
          resolve(data.items)
        })
        .catch(reject)
    })
  },
  getWeeklySummary: ({ commit }) => {
    return new Promise((resolve, reject) => {
      axios({
        url: '/dashboards/weekly',
        method: 'GET'
      })
        .then(({ data }) => {
          commit(
            'SET_WEEKLY_SUMMARY',
            data.items.filter((x) => x.items.length > 0)
          )
          resolve(data.items)
        })
        .catch(reject)
    })
  },
  getLegacyExports: ({ commit }) => {
    return new Promise((resolve, reject) => {
      axios({
        url: '/exports/legacy',
        params: { sort_dec: true, per_page: 10, sort_by: 'created_on' },
        method: 'GET'
      })
        .then(({ data }) => {
          commit('SET_LEGACY_EXPORTS', data.items)
          resolve(data)
        })
        .catch(reject)
    })
  },
  generateLegacyExports: ({}) => {
    return new Promise((resolve, reject) => {
      axios({
        url: '/exports/legacy/generate',
        data: {},
        method: 'POST'
      })
        .then(({ data }) => {
          resolve(true)
        })
        .catch(reject)
    })
  },
  // overdue tasks
  getOverdueTasks: ({ commit, rootState }, { pager }) => {
    const prefilter = `overdue:${EQ_OPERATOR}:true`
    const actual = toFilterString(rootState.app.appliedFilters[OVERDUETASK])
    const filters = actual ? [prefilter, actual].join(AND_SUB_SEPARATOR) : prefilter
    return new Promise((resolve, reject) => {
      axios({
        url: '/addons/tasks',
        params: { filters, include: 'entity,owner', ...pager },
        method: 'GET'
      })
        .then(({ data }) => {
          commit('SET_OVERDUE_TASKS', data.items)
          commit('SET_OVERDUE_TASKS_COUNT', data.count)
          resolve(data)
        })
        .catch(reject)
    })
  },
  clearOverdueTasks: ({ commit }) => {
    commit('SET_OVERDUE_TASKS', [])
    commit('SET_OVERDUE_TASKS_COUNT', null)
  },
  // pending tasks
  getPendingTasks: ({ commit, rootState }, { pager }) => {
    const actual = toFilterString(rootState.app.appliedFilters[PENDINGTASKS])
    const prefilters = [`overdue:${EQ_OPERATOR}:false`, `completed:${EQ_OPERATOR}:false`].join(AND_SEPARATOR)
    const filters = actual ? [prefilters, actual].join(AND_SUB_SEPARATOR) : prefilters
    return new Promise((resolve, reject) => {
      axios({
        url: '/addons/tasks',
        params: { filters, include: 'entity,owner', ...pager },
        method: 'GET'
      })
        .then(({ data }) => {
          commit('SET_PENDING_TASKS', data.items)
          commit('SET_PENDING_TASKS_COUNT', data.count)
          resolve(data)
        })
        .catch(reject)
    })
  },
  clearPendingTasks: ({ commit }) => {
    commit('SET_PENDING_TASKS', [])
    commit('SET_PENDING_TASKS_COUNT', null)
  },
  // overdue brokers
  getOverdueBrokers: ({ commit, rootState }, { pager }) => {
    const prefilters = ['overdue', 'active'].map((x) => `${x}:${EQ_OPERATOR}:true`).join(AND_SEPARATOR)
    const actual = toFilterString(rootState.app.appliedFilters[OVERDUEBROKERS])
    const filters = actual ? [prefilters, actual].join(AND_SUB_SEPARATOR) : prefilters

    return new Promise((resolve, reject) => {
      axios({
        url: '/brokers',
        params: { filters, include: 'firm', ...pager, sort_by: 'updated_on' },
        method: 'GET'
      })
        .then(({ data }) => {
          commit('SET_OVERDUE_BROKERS', data.items)
          commit('SET_OVERDUE_BROKERS_COUNT', data.count)
          resolve(data)
        })
        .catch(reject)
    })
  },
  clearOverdueBrokers: ({ commit }) => {
    commit('SET_OVERDUE_BROKERS', [])
    commit('SET_OVERDUE_BROKERS_COUNT', null)
  },
  // non-compliants
  getNonCompliantDeals: ({ commit, rootState }, { pager }) => {
    let steps = ['bought', 'sold', 'approved', 'delivered', 'paid']
      .map((x) => `step:${EQ_OPERATOR}:${x}`)
      .join(OR_SEPARATOR)
    let primaries = [`compliant:${EQ_OPERATOR}:${false}`, `deal_type:${EQ_OPERATOR}:life-individual`].join(
      AND_SEPARATOR
    )
    let prefilters = [primaries, steps].join(AND_SUB_SEPARATOR)
    const actual = toFilterString(rootState.app.appliedFilters[NONCOMPLIANTDEALS])
    const filters = actual ? [prefilters, actual].join(AND_SUB_SEPARATOR) : prefilters
    return new Promise((resolve, reject) => {
      axios({
        url: '/deals',
        params: { include: 'firm,board', filters, ...pager, sort_by: 'updated_on' },
        method: 'GET'
      })
        .then(({ data }) => {
          commit('SET_NON_COMPLIANT_DEALS', data.items)
          commit('SET_NON_COMPLIANT_DEALS_COUNT', data.count)
          resolve(data)
        })
        .catch(reject)
    })
  },
  clearNonCompliantDeals: ({ commit }) => {
    commit('SET_NON_COMPLIANT_DEALS', [])
    commit('SET_NON_COMPLIANT_DEALS_COUNT', null)
  },
  // pending contact
  getPendingContactDeals: ({ commit, rootState }, { pager }) => {
    const step = `step:${EQ_OPERATOR}:to_contact`
    const statuses = ['pending', 'ongoing'].map((x) => `status:${EQ_OPERATOR}:${x}`).join(OR_SEPARATOR)
    const prefilters = [step, statuses].join(AND_SUB_SEPARATOR)
    const actual = toFilterString(rootState.app.appliedFilters[PENDINGCONTACTDEALS])
    const filters = actual ? [prefilters, actual].join(AND_SUB_SEPARATOR) : prefilters
    return new Promise((resolve, reject) => {
      axios({
        url: '/deals',
        params: { filters, include: 'broker,advisor,board', ...pager },
        method: 'GET'
      })
        .then(({ data }) => {
          commit('SET_PENDING_CONTACT_DEALS', data.items)
          commit('SET_PENDING_CONTACT_DEALS_COUNT', data.count)
          resolve(data)
        })
        .catch(reject)
    })
  },
  clearPendingContactDeals: ({ commit }) => {
    commit('SET_PENDING_CONTACT_DEALS', [])
    commit('SET_PENDING_CONTACT_DEALS_COUNT', null)
  },
  // pending (for broker)
  getPendingBrokerDeals: ({ commit, rootState }, { pager }) => {
    let step = `step:${EQ_OPERATOR}:to_contact`
    const status = `status:${EQ_OPERATOR}:ongoing`
    const prefilters = [step, status].join(AND_SEPARATOR)
    const actual = toFilterString(rootState.app.appliedFilters[PENDINGBROKERDEALS])
    const filters = actual ? [prefilters, actual].join(AND_SUB_SEPARATOR) : prefilters
    return new Promise((resolve, reject) => {
      axios({
        url: '/deals',
        params: { filters, include: 'advisor,board', ...pager },
        method: 'GET'
      })
        .then(({ data }) => {
          commit('SET_PENDING_BROKER_DEALS', data.items)
          commit('SET_PENDING_BROKER_DEALS_COUNT', data.count)
          resolve(data)
        })
        .catch(reject)
    })
  },
  clearPendingBrokerDeals: ({ commit }) => {
    commit('SET_PENDING_BROKER_DEALS', [])
    commit('SET_PENDING_BROKER_DEALS_COUNT', null)
  },
  // contacted (for broker)
  getContactedBrokerDeals: ({ commit, rootState }, { pager }) => {
    let status = `status:${EQ_OPERATOR}:ongoing`
    const steps = ['contacted', 'scheduled', 'met'].map((x) => `step:${EQ_OPERATOR}:${x}`).join(OR_SEPARATOR)
    const prefilters = [steps, status].join(AND_SUB_SEPARATOR)
    const actual = toFilterString(rootState.app.appliedFilters[CONTACTEDBROKERDEALS])
    const filters = actual ? [prefilters, actual].join(AND_SUB_SEPARATOR) : prefilters
    return new Promise((resolve, reject) => {
      axios({
        url: '/deals',
        params: { filters, include: 'advisor,board', ...pager },
        method: 'GET'
      })
        .then(({ data }) => {
          commit('SET_CONTACTED_BROKER_DEALS', data.items)
          commit('SET_CONTACTED_BROKER_DEALS_COUNT', data.count)
          resolve(data.items)
        })
        .catch(reject)
    })
  },
  clearContactedBrokerDeals: ({ commit }) => {
    commit('SET_CONTACTED_BROKER_DEALS', [])
    commit('SET_CONTACTED_BROKER_DEALS_COUNT', null)
  },
  // sold (for broker)
  getSoldBrokerDeals: ({ commit, rootState }, { pager }) => {
    let steps = ['bought', 'sold', 'approved', 'delivered'].map((x) => `step:${EQ_OPERATOR}:${x}`).join(OR_SEPARATOR)
    let primaries = [`status:${EQ_OPERATOR}:ongoing`, `category:${EQ_OPERATOR}:life`].join(AND_SEPARATOR)
    let prefilters = [primaries, steps].join(AND_SUB_SEPARATOR)
    const actual = toFilterString(rootState.app.appliedFilters[SOLDBROKERDEALS])
    const filters = actual ? [prefilters, actual].join(AND_SUB_SEPARATOR) : prefilters

    return new Promise((resolve, reject) => {
      axios({
        url: '/deals',
        params: { filters, include: 'advisor,board', ...pager },
        method: 'GET'
      })
        .then(({ data }) => {
          commit('SET_SOLD_BROKER_DEALS', data.items)
          commit('SET_SOLD_BROKER_DEALS_COUNT', data.count)
          resolve(data.items)
        })
        .catch(reject)
    })
  },
  clearSoldBrokerDeals: ({ commit }) => {
    commit('SET_SOLD_BROKER_DEALS', [])
    commit('SET_SOLD_BROKER_DEALS_COUNT', null)
  },
  // completed (for broker)
  getCompletedBrokerDeals: ({ commit, rootState }, { pager }) => {
    let step = `step:${EQ_OPERATOR}:paid`
    let status = `status:${EQ_OPERATOR}:finalized`
    let prefilters = [status, step].join(AND_SEPARATOR)
    const actual = toFilterString(rootState.app.appliedFilters[COMPLETEDBROKERDEALS])
    const filters = actual ? [prefilters, actual].join(AND_SUB_SEPARATOR) : prefilters
    return new Promise((resolve, reject) => {
      axios({
        url: '/deals',
        params: { filters, include: 'advisor,board', ...pager },
        method: 'GET'
      })
        .then(({ data }) => {
          commit('SET_COMPLETED_BROKER_DEALS', data.items)
          commit('SET_COMPLETED_BROKER_DEALS_COUNT', data.count)
          resolve(data)
        })
        .catch(reject)
    })
  },
  clearCompletedBrokerDeals: ({ commit }) => {
    commit('SET_COMPLETED_BROKER_DEALS', [])
    commit('SET_COMPLETED_BROKER_DEALS_COUNT', null)
  }
}

const getters = {
  daily: (state) => state.summary.daily || [],
  weekly: (state) => state.summary.weekly || []
}
export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
