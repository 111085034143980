<template>
  <v-list-item class="py-1">
    <v-list-item-content>
      <v-list-item-subtitle>{{ $t('labels.refusal') }}</v-list-item-subtitle>
      <v-list-item-title>{{ refusal }}</v-list-item-title>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
import FetchMixin from './Fetch'
export default {
  name: 'RefusalListItem',
  mixins: [FetchMixin],
  props: {
    deal: { type: Object, required: true }
  },
  computed: {
    refusal() {
      let match = this.localRefusals.find((x) => x.value === this.deal.refusal)
      return match ? match.name : ''
    }
  }
}
</script>

<style></style>
