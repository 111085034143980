export default [
  {
    path: '/transactions',
    component: () => import('@argon/app/components/AppLayout'),
    props: { nav: 'organizations' },
    children: [
      {
        path: '',
        name: 'transactions',
        component: () => import('./views/Transactions'),
        props: true,
        meta: {
          perimeterAction: 'read'
        }
      }
    ]
  }
]
