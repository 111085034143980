import { axios } from '@argon/app/axios'
import { reduce } from 'lodash/collection'

const NAMESPACE = 'statuses'
const state = {
  statuses: []
}

const mutations = {
  SET_STATUSES: (state, payload) => {
    state.statuses = payload
  }
}
const actions = {
  listStatuses: ({ commit, dispatch }) => {
    return new Promise((resolve, reject) => {
      axios({
        url: '/deal-statuses',
        method: 'GET'
      })
        .then(({ data }) => {
          commit('SET_STATUSES', data.items)
          resolve(data.items)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
}
const getters = {
  statuses: (state) => state.statuses
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}

export { NAMESPACE }
