<template>
  <v-icon small v-text="readIcon" v-if="readOnly" />
  <v-btn-toggle v-model="toggled" mandatory class="elevation-0" v-else>
    <v-btn small icon class="tri-state depressed success">
      <v-icon small v-text="$vuetify.icons.values.complete" />
    </v-btn>
    <v-btn small icon class="tri-state depressed">
      <v-icon small v-text="$vuetify.icons.values.help" />
    </v-btn>
    <v-btn small icon class="tri-state depressed error">
      <v-icon small v-text="$vuetify.icons.values.close" />
    </v-btn>
  </v-btn-toggle>
</template>

<script>
// TODO: WIP
export default {
  name: 'VTriState',
  props: {
    readOnly: { type: Boolean, default: false },
    choice: [Boolean, String],
    accept: String,
    reject: String,
    neutral: String,
    onChanged: Function
  },
  data: () => ({
    toggle: null
  }),
  computed: {
    toggled: {
      get() {
        switch (this.choice) {
          case true:
          case this.accept:
            return 0
            break
          case false:
          case this.reject:
            return 2
            break
          default:
            return 1
            break
        }
      },
      set(v) {
        switch (v) {
          case 0:
            if (this.accept) {
              this.onChanged(this.accept)
            } else {
              this.onChanged(true)
            }
            break
          case 2:
            if (this.reject) {
              this.onChanged(this.reject)
            } else {
              this.onChanged(false)
            }
            break
          default:
            if (this.neutral) {
              this.onChanged(this.neutral)
            } else {
              this.onChanged(null)
            }
            break
        }
      }
    },
    readIcon() {
      switch (this.choice) {
        case true:
        case this.accept:
          return this.$vuetify.icons.values.complete
          break
        case false:
        case this.reject:
          return this.$vuetify.icons.values.close
          break
        default:
          return this.$vuetify.icons.values.help
          break
      }
    }
  }
}
</script>

<style lang="css">
.tri-state.v-btn {
  padding: 0 8px !important;
  min-width: 0 !important;
}
</style>
