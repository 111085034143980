export default [
  {
    path: '/me/overview',
    component: () => import('@argon/app/components/AppLayout'),
    props: { nav: 'main' },
    children: [
      {
        path: '',
        name: 'overview',
        component: () => import('./views/Overview'),
        meta: {
          perimeterAction: 'read'
        }
      }
    ]
  },
  {
    path: '/overview',
    component: () => import('@argon/app/components/AppLayout'),
    props: { nav: 'organizations' },
    children: [
      {
        path: '',
        name: 'orgOverview',
        component: () => import('./views/OrgOverview'),
        props: true,
        meta: {
          perimeterAction: 'read'
        }
      }
    ]
  },
  {
    path: '/',
    redirect: '/overview'
  }
]
