<template>
  <div>
    <VWidget content-class="pa-0">
      <template v-slot:title>{{ $t('applicantResponses') }}</template>
      <template v-slot:header-actions>
        <applicant-status :status="status" />
        <v-btn small icon @click="showModal" v-if="!readOnly">
          <v-icon small>{{ $vuetify.icons.values.edit }}</v-icon>
        </v-btn>
      </template>
      <template v-slot:content>
        <component :is="dynamicComponent" :responses="policy.deal_client.meta.info" read-only />
      </template>
    </VWidget>
    <responses-modal :policy="policy" :submit="doSubmit" :dismiss="dismiss" v-if="shouldShow" />
  </div>
</template>

<script>
import { VWidget } from '@argon/app/components'
import VTriState from '../components/VTriState'
import ApplicantStatus from './ApplicantStatus'
import { mapActions } from 'vuex'
import { NAMESPACE } from '../store'
import ResponsesModal from './ResponsesModal'
import { IAGResponses } from '../iag'
import { CLAResponses } from '../cla'
import policyPerimeter from '../perimeters'

export default {
  name: 'VResponses',
  perimeters: [policyPerimeter],
  components: { VWidget, ApplicantStatus, ResponsesModal, IAGResponses, CLAResponses },
  props: {
    policy: { type: Object, required: true },
    readOnly: { type: Boolean, default: false }
  },
  data: () => ({
    shouldShow: false,
    shouldShowReadOnly: false
  }),
  computed: {
    dynamicComponent() {
      switch (this.policy.insurer) {
        case 'IAG':
          return 'IAGResponses'
          break

        default:
          return 'CLAResponses'
          break
      }
    },
    status() {
      return this.policy.deal_client.status
    },
    responses() {
      return this.policy.deal_client.meta.info
    },
    dealId() {
      return this.policy.deal_id
    },
    clientId() {
      return this.policy.client_id
    }
  },
  methods: {
    ...mapActions(NAMESPACE, ['patchDealClient']),
    dismiss() {
      this.shouldShow = false
      this.shouldShowReadOnly = false
    },
    showReadOnlyModal() {
      this.shouldShowReadOnly = true
    },
    showModal() {
      this.shouldShow = true
    },
    doSubmit(info) {
      let payload = {
        dealId: this.dealId,
        clientId: this.clientId,
        data: { meta: { info } }
      }
      return this.patchDealClient(payload)
    }
  }
}
</script>

<style lang="css">
.tri-state.v-list__tile__action {
  min-width: 90px;
}
</style>

<i18n>
{
  "en": {
    "acceptedDisability": "@:labels.acceptedDisability",
    "acceptedInsurance": "@:labels.acceptedInsurance",
    "acceptedInsuranceConditions": "@:labels.acceptedInsuranceConditions",
    "acceptedPADAgreement": "@:labels.acceptedPADAgreement",
    "badInsuranceClaim": "@:labels.badInsuranceClaim",
    "confirmedAuthorization": "@:labels.confirmedAuthorization",
    "isSmoker": "@:labels.isSmoker",
    "medicalCondition": "@:labels.medicalCondition",
    "noResponse": "@:labels.noResponse",
    "applicantResponses": "@:labels.applicantResponses",
    "verifiedDealInfo": "@:labels.verifiedDealInfo"
  },
  "fr": {
    "acceptedDisability": "@:labels.acceptedDisability",
    "acceptedInsurance": "@:labels.acceptedInsurance",
    "acceptedInsuranceConditions": "@:labels.acceptedInsuranceConditions",
    "acceptedPADAgreement": "@:labels.acceptedPADAgreement",
    "badInsuranceClaim": "@:labels.badInsuranceClaim",
    "confirmedAuthorization": "@:labels.confirmedAuthorization",
    "isSmoker": "@:labels.isSmoker",
    "medicalCondition": "@:labels.medicalCondition",
    "noResponse": "@:labels.noResponse",
    "applicantResponses": "@:labels.applicantResponses",
    "verifiedDealInfo": "@:labels.verifiedDealInfo"
  }
}
</i18n>
