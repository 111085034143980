import { registerModule } from '@argon/app/helpers'

import routes from './router'
import store, { NAMESPACE } from '@argon/iam/organizations/store'

const navItems = {
  organizations: [
    {
      name: 'settingsHeader',
      weight: 50,
      component: () => import('./components/SettingsHeader')
    },
    {
      name: 'configurations',
      weight: 60,
      component: () => import('@argon/iam/organizations/components/OrganizationConfigsNavItem')
    }
  ],
  default: [
    {
      name: 'systemOrganizations',
      weight: 100,
      component: () => import('@argon/iam/organizations/components/SystemOrganizationsNavItem')
    },
    {
      name: 'selector',
      weight: 0,
      component: () => import('@argon/iam/components/ContextSelector')
    }
  ]
}

const VuePlugin = {
  install: (Vue, options) => {
    registerModule(options.router, options.store, options.i18n, {
      namespace: NAMESPACE,
      routes: routes,
      namespaceStore: store,
      navItems: navItems
    })
  }
}

export default VuePlugin
