<template>
  <v-select
    v-model="localValue"
    :items="items"
    hide-no-data
    :label="$t('labels.commissioning')"
    required
    :error-messages="errors"
    clearable
  >
    <template v-slot:item="{ item }">
      {{ $t(`labels.${item}`) }}
    </template>
    <template v-slot:selection="{ item }">
      {{ $t(`labels.${item}`) }}
    </template>
  </v-select>
</template>

<script>
import { CustomFieldMixin } from '@argon/app/mixins'
export default {
  name: 'CommissionField',
  mixins: [CustomFieldMixin],
  data: () => ({
    items: ['50-50', '60-40', '100-0']
  })
}
</script>
