<template>
  <VWidget content-class="pa-0">
    <template v-slot:title>{{ $t('labels.beneficiaries') }}</template>
    <template v-slot:header-actions v-if="!readOnly">
      <v-btn icon small @click="create">
        <v-icon small>{{ $vuetify.icons.values.plus }}</v-icon>
      </v-btn>
    </template>
    <template v-slot:content>
      <v-list dense subheader v-for="(beneficiary, idx) in beneficiaries" :key="`beneficiary-${idx}`">
        <v-divider v-if="idx !== 0" />
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="subheading">{{ beneficiary.display }}</v-list-item-title>
          </v-list-item-content>
          <v-list-item-action v-if="!readOnly">
            <v-btn small icon @click="remove(beneficiary)">
              <v-icon small>{{ $vuetify.icons.values.trash }}</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
        <v-divider />
        <v-list-item>
          <v-list-item-subtitle>{{ $t('labels.relation') }}</v-list-item-subtitle>
          <v-list-item-title class="text-right">{{ beneficiary.relation }}</v-list-item-title>
        </v-list-item>
        <v-divider />
        <v-list-item>
          <v-list-item-subtitle>{{ $t('labels.birthDate') }}</v-list-item-subtitle>
          <v-list-item-title class="text-right">{{ transformDate(beneficiary.birthdate) }}</v-list-item-title>
        </v-list-item>
        <v-divider />
        <v-list-item>
          <v-list-item-subtitle>{{ $t('labels.percentage') }}</v-list-item-subtitle>
          <v-list-item-title class="text-right">{{ beneficiary.percentage }} %</v-list-item-title>
        </v-list-item>
      </v-list>
    </template>
  </VWidget>
</template>

<script>
import { VWidget } from '@argon/app/components'
import { mapGetters, mapState, mapActions } from 'vuex'
import { NAMESPACE as ME_NAMESPACE } from '@argon/iam/me/store'
import { formatDT } from '@argon/app/helpers'
import { NAMESPACE } from '../store'
import policyPerimeter from '../perimeters'

export default {
  name: 'VBeneficiaries',
  perimeters: [policyPerimeter],
  components: { VWidget },

  props: {
    policy: { type: Object, required: true },
    readOnly: { type: Boolean, default: false }
  },
  computed: {
    ...mapGetters(ME_NAMESPACE, ['timezone', 'locale']),
    beneficiaries() {
      return this.policy.beneficiaries
    },
    dealId() {
      return this.policy.deal_id
    },
    policyId() {
      return this.policy.id
    }
  },
  methods: {
    ...mapActions(NAMESPACE, ['deleteBeneficiary']),
    transformDate(value) {
      return value ? formatDT(value, { format: 'L', tz: this.timezone, locale: this.locale }) : ''
    },
    edit(beneficiary) {
      this.$router.push({
        name: 'nestedBeneficiary',
        params: {
          ...this.$route.params,
          policyId: this.policyId,
          beneficiaryId: beneficiary.id
        }
      })
    },
    remove(beneficiary) {
      this.deleteBeneficiary({
        dealId: this.dealId,
        policyId: this.policyId,
        beneficiaryId: beneficiary.id
      })
    },
    create() {
      this.$router.push({
        name: 'nestedBeneficiary',
        params: {
          ...this.$route.params,
          policyId: this.policyId,
          beneficiaryId: 'new'
        }
      })
    }
  }
}
</script>

<style></style>
