/* eslint-disable no-console */

import { register } from 'register-service-worker'

if (process.env.NODE_ENV === 'production') {
  register(`${process.env.BASE_URL}service-worker.js`, {
    registered(registration) {
      setInterval(() => {
        registration.update()
      }, 1000 * 60 * 30) // e.g. hourly checks
    },
    updated(registration) {
      document.dispatchEvent(new CustomEvent('swUpdated', { detail: registration }))
    },
    offline() {
      console.log('No internet connection found. App is running in offline mode.')
    },
    error(error) {
      console.error('Error during service worker registration:', error)
    }
  })
}
