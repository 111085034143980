import { mapGetters } from 'vuex'
import { NAMESPACE as ME_NAMESPACE } from '@argon/iam/me/store'
import { money } from '../../utils'

export default {
  name: 'InsuranceInfo',
  props: {
    idx: { type: Number, required: false, default: 0 },
    policy: { type: Object, required: true },
    readOnly: { type: Boolean, default: false }
  },
  computed: {
    ...mapGetters(ME_NAMESPACE, ['locale'])
  },
  methods: {
    toCurrency(v) {
      return money(v, this.locale)
    }
  }
}
