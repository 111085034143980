export default [
  {
    path: '/signin',
    component: () => import('@argon/app/components/BaseLayout'),
    children: [
      {
        path: '',
        name: 'signin',
        props: true,
        component: () => import('@argon/iam/auth/views/Signin')
      }
    ]
  },
  {
    path: '/register',
    component: () => import('@argon/app/components/BaseLayout'),
    children: [
      {
        path: '',
        name: 'register',
        component: () => import('./views/Register')
      }
    ]
  },
  {
    path: '/verify/:token',
    component: () => import('@argon/app/components/BaseLayout'),
    children: [
      {
        path: '',
        name: 'verify',
        component: () => import('@argon/iam/auth/views/Verify'),
        props: true
      }
    ]
  },
  {
    path: '/forgot-password',
    component: () => import('@argon/app/components/BaseLayout'),
    children: [
      {
        path: '',
        name: 'forgotPassword',
        component: () => import('@argon/iam/auth/views/ForgotPassword')
      }
    ]
  },
  {
    path: '/change-password/:token',
    component: () => import('@argon/app/components/BaseLayout'),
    children: [
      {
        path: '',
        name: 'changePassword',
        component: () => import('@argon/iam/auth/views/ChangePassword'),
        props: true
      }
    ]
  }
]
