import { axios, EQ_OPERATOR, AND_SEPARATOR } from '@argon/app/axios'

const NAMESPACE = 'brokers'

const state = {
  brokers: [],
  broker: null
}

const mutations = {
  SET_BROKERS: (state, payload) => {
    state.brokers = payload
  },
  SET_BROKER: (state, payload) => {
    state.broker = payload
  }
}
const actions = {
  getBroker: ({ commit }, brokerId) => {
    return new Promise((resolve, reject) => {
      axios({
        url: `/brokers/${brokerId}`,
        method: 'GET',
        params: { include: 'firm,account' }
      })
        .then(({ data }) => {
          commit('SET_BROKER', data)
          resolve(data)
        })
        .catch(reject)
    })
  },
  listBrokers: ({ commit }, payload) => {
    const { filters, pager } = payload
    const extra = `active:${EQ_OPERATOR}:true`
    let filterParams = filters ? [filters, extra].join(AND_SEPARATOR) : extra
    let pagerParams = pager || {}
    return new Promise((resolve, reject) => {
      axios({
        url: '/groups/simpl-brokers/users',
        method: 'GET',
        params: { ...pagerParams, filters: filterParams, include: 'user,account' }
      })
        .then(({ data }) => {
          // we need the user display so let's map it
          commit('SET_BROKERS', data.items)
          resolve(data.items)
        })
        .catch(reject)
    })
  }
}
const getters = {
  brokers: (state) => state.brokers
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}

export { NAMESPACE }
