<template>
  <v-row align="baseline">
    <v-col cols="8">
      <v-text-field
        v-model="address"
        :placeholder="$t('email')"
        single-line
        :error-messages="addressErrors"
        @input="$v.address.$touch()"
        @blur="$v.address.$touch()"
        prepend-icon="$vuetify.icons.values.email"
        class="mb-0 pt-0"
      />
    </v-col>
    <v-col cols="3">
      <v-select
        v-model="emailType"
        :placeholder="$t('emailType')"
        :items="types"
        item-text="display"
        item-value="value"
        class="mb-0 pt-0"
      />
    </v-col>
    <v-col cols="1">
      <v-btn small icon @click="removeOne(idx)">
        <v-icon small>{{ $vuetify.icons.values.close }}</v-icon>
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
import { email, required } from 'vuelidate/lib/validators'

export default {
  validations() {
    return {
      address: this.required ? { email, required } : { email }
    }
  },
  data: function () {
    return {
      types: [
        { value: 'work', display: this.$t('work') },
        { value: 'home', display: this.$t('home') },
        { value: 'unknown', display: this.$t('other') }
      ]
    }
  },
  props: {
    idx: Number,
    required: { type: Boolean, default: false },
    email: Object,
    removeOne: Function,
    update: Function,
    setParentError: Function,
    clearParentError: Function
  },
  computed: {
    address: {
      get() {
        return this.email.address
      },
      set(value) {
        this.update(this.idx, 'address', value)
      }
    },
    emailType: {
      get() {
        return this.email.type
      },
      set(value) {
        this.update(this.idx, 'type', value)
      }
    },
    addressErrors() {
      const errors = []
      if (!this.$v.address.$dirty) return errors
      if (this.required && !this.$v.address.required) {
        errors.push(this.$t('labels.required'))
      }
      !this.$v.address.email && errors.push(this.$t('labels.invalidEmail'))
      return errors
    }
  },
  watch: {
    '$v.address.$error': function _watch$vAddress$invalid(v) {
      if (v) {
        this.setParentError(this.idx)
      } else {
        this.clearParentError(this.idx)
      }
    }
  }
}
</script>

<i18n>
{
  "en": {
    "email": "@:labels.email",
    "emailType": "@:labels.emailType",
    "home": "@:labels.home",
    "invalidEmail": "@:labels.invalidEmail",
    "other": "@:labels.other",
    "work": "@:labels.work"
  },
  "fr": {
    "email": "@:labels.email",
    "emailType": "@:labels.emailType",
    "home": "@:labels.emailType",
    "invalidEmail": "@:labels.invalidEmail",
    "other": "@:labels.other",
    "work": "@:labels.work"
  }
}
</i18n>
