import { axios } from '@argon/app/axios'
import { toFilterString } from '@argon/app/helpers'

const NAMESPACE = 'transactions'

const state = {
  transactions: [],
  filterObject: null,
  tableItemsCount: null,
  tablePagination: {}
}

const mutations = {
  SET_TRANSACTIONS: (state, payload) => {
    state.transactions = payload
  },
  SET_TABLE_PAGINATION: (state, payload) => {
    state.tablePagination = payload
  },
  SET_TABLE_ITEMS_COUNT: (state, payload) => {
    state.tableItemsCount = payload
  },
  SET_FILTERS: (state, payload) => {
    state.filterObject = payload
  }
}

const actions = {
  setFilters: ({ commit }, filterObject) => {
    const stamp = new Date().getTime()
    commit('SET_FILTERS', { ...filterObject, stamp })
  },
  listTransactions: ({ commit, rootState }, { pager }) => {
    const filters = toFilterString(rootState.app.appliedFilters[NAMESPACE])
    let pagerParams = pager || state.tablePagination
    let filterParams = filters ? { filters } : {}
    let params = { ...filterParams, ...pagerParams }
    return new Promise((resolve, reject) => {
      axios({
        url: '/insurer-transactions',
        method: 'GET',
        params
      })
        .then(({ data }) => {
          commit('SET_TRANSACTIONS', data.items)
          commit('SET_TABLE_ITEMS_COUNT', data.count)
          commit('SET_TABLE_PAGINATION', pagerParams)
          resolve(data.items)
        })
        .catch(reject)
    })
  },
  clearTransactions: ({ commit }) => {
    return new Promise((resolve, reject) => {
      commit('SET_TRANSACTIONS', [])
      resolve(true)
    })
  }
}
const getters = {
  transactions: (state) => state.transactions
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}

export { NAMESPACE }
