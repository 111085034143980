import { axios } from '@argon/app/axios'

const NAMESPACE = 'productLog'

const state = {
  logs: []
}

const mutations = {
  SET_LOGS: (state, payload) => {
    state.logs = payload
  }
}
const actions = {
  listLogs: ({ commit }, entityId) => {
    return new Promise((resolve, reject) => {
      axios({
        url: `/deals/${entityId}/product-logbook`,
        method: 'GET'
      })
        .then(({ data }) => {
          commit('SET_LOGS', data.items)
          resolve(data.items)
        })
        .catch(reject)
    })
  },
  clearLogs: ({ commit }) => {
    commit('SET_LOGS', [])
  },
  getLogbookEntry: ({}, payload) => {
    const { entityId, logId } = payload
    return new Promise((resolve, reject) => {
      axios({
        url: `/deals/${entityId}/product-logbook/${logId}`,
        method: 'GET'
      })
        .then(({ data }) => {
          resolve(data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
}
const getters = {
  logs: (state) => state.logs
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}

export { NAMESPACE }
