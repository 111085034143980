<template>
  <div>
    <v-row>
      <v-col cols="12">
        <v-toolbar flat dense color="transparent">
          <v-toolbar-title class="subheading">{{ $t('addresses') }}</v-toolbar-title>
          <v-spacer />
          <v-btn icon small>
            <v-icon small @click="addOne">{{ $vuetify.icons.values.add }}</v-icon>
          </v-btn>
        </v-toolbar>
      </v-col>
      <v-col cols="12" v-if="hasOne">
        <AddressLine
          v-for="(address, idx) in localAddresses"
          :key="idx"
          :idx="idx"
          :address="address"
          :remove-one="removeOne"
          :update="update"
          :required="required"
          :set-parent-error="setError"
          :clear-parent-error="clearError"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import AddressLine from './AddressLine'
import ErrorMixin from './WidgetErrorMixin.js'

const WIDGET = 'adddress'
const DEFAULT = {
  country_code: null,
  province: null,
  city: null,
  street_address: null,
  postal_code: null,
  type: 'current'
}

export default {
  name: 'AddressesWidget',
  mixins: [ErrorMixin],
  components: { AddressLine },
  props: {
    required: { type: Boolean, default: false },
    addresses: { type: Array, default: () => [] },
    updateAddresses: { type: Function, required: true }
  },
  data: () => ({
    localAddresses: [{ ...DEFAULT }]
  }),
  computed: {
    hasOne() {
      return this.localAddresses.length > 0
    }
  },
  watch: {
    hasErrors: function (v) {
      if (v) {
        this.setParentError(WIDGET)
      } else {
        this.clearParentError(WIDGET)
      }
    }
  },
  methods: {
    sendUpdated() {
      let cleaned = this.localAddresses.filter(
        (item) => item.country_code && item.city && item.street_address && item.postal_code && item.type
      )
      this.updateAddresses(cleaned)
    },
    addOne() {
      this.localAddresses = [...this.localAddresses, { ...DEFAULT }]
    },
    removeOne(idx) {
      if (this.localAddresses.length > 1) {
        this.localAddresses = this.localAddresses.filter((item, i) => i !== idx)
      } else {
        this.localAddresses = this.required ? [{ ...DEFAULT }] : []
      }
      this.sendUpdated()
    },
    update(idx, key, value) {
      this.localAddresses[idx][key] = value
      this.sendUpdated()
    }
  },
  created() {
    if (this.addresses.length > 0) {
      this.localAddresses = [...this.addresses]
    } else {
      this.localAddresses = this.required ? [{ ...DEFAULT }] : []
    }
  }
}
</script>

<style lang="scss" scoped>
.v-toolbar {
  >>> .v-toolbar__content {
    padding: 0;
    margin-bottom: 5px;

    .v-btn--icon {
      margin-right: 6px;
      margin-left: 0;
    }
  }
}
</style>

<i18n>
{
  "en": {
    "addAddress": "Add Address",
    "addresses": "Addresses"
  },
  "fr": {
    "addAddress": "Ajouter une adresse",
    "addresses": "Adresses"
  }
}
</i18n>
