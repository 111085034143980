import { registerModule } from '@argon/app/helpers'
import store, { NAMESPACE } from '@argon/evo/flows/store'
import routes from '@argon/evo/flows/router'

// const navItems = {
//   organizations: [
//     {
//       name: 'flows',
//       weight: 6,
//       component: () => import('@argon/evo/flows/components/FlowsNavItem')
//     }
//   ]
// }

const VuePlugin = {
  install: (Vue, options) => {
    registerModule(options.router, options.store, options.i18n, {
      // navItems: navItems,
      namespace: NAMESPACE,
      routes: routes,
      namespaceStore: store
    })
  }
}

export default VuePlugin
